import { useCallback, useEffect, useMemo, useState } from 'react';
import { IoMdArrowDropright, IoMdArrowDropleft } from 'react-icons/io';
import { NavLink } from 'react-router-dom';
import './style.css';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { logout } from '../../../services/auth';
import useUsuarioLocal from '../../../hooks/usuarioLocal.hook';
import { ButtonsPagesPrincipal } from '../../../pages/Principal/components/PagesSystem';
import { BsChevronCompactUp } from 'react-icons/bs';
import { Box, IconButton, InputBase, Paper, Typography, MenuItem } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
// import { buscarParametrizacaoPage } from '../../../services/parametrizacaocard.service';


const Menu = (item, index, filtro, filterOpenSubmenu) => {
  const linkAtivo = useMemo(() => {
    return item.to === item.active;
  }, [item]);

  const itemCorrespondeAoFiltro =
    item.setorNome.toLowerCase().includes(filtro.toLowerCase()) ||
    (item.paginasDoSetor &&
      item.paginasDoSetor.some((subItem) =>
        subItem.label.toLowerCase().includes(filtro.toLowerCase())
      ));

  if (!itemCorrespondeAoFiltro) {
    return null; // Não renderizar o item se não corresponder ao filtro
  }
  // const [statusCards, setStatusCards] = useState([]); 

  // useEffect(() => {
  //   buscarParametrizacaoPage().then((res) => {
  //     setStatusCards(res); 
  //   });

  // }, []);


  return (
    <div className="d-flex w-100" key={index}>
      <div
        className="setorColorItemMenu"
        style={{ background: item.setorColor }}
      ></div>
      <div className={`menu-item ${linkAtivo && 'menu-item-ativo'}`}>
        <NavLink to={item.to && `/${item.to}`} disabled={!item.to}>
          {({ isActive }) => {
            const [open, setOpen] = useState(false);

            return (
              <>
                <button
                  type="button"
                  className={'link-btn-inactive'}
                  onClick={() => {
                    setOpen(!open)
                  }}
                >
                  <div className="data">
                    <div className="img">{item.img}</div>
                    <p>{item.setorNome}</p>
                  </div>
                  {!item.to && (
                    <BsChevronCompactUp
                      className={
                        !open
                          ? 'chevron-submenu-opened'
                          : 'chevron-submenu-closed'
                      }
                    />
                  )}
                </button>

                <div className="submenu-drop">
                  {item.paginasDoSetor.map((item, index) => (
                    <NavLink
                      key={index}
                      to={`/${item.to}`}
                      className={!open && !filterOpenSubmenu && 'submenu-invisible'}
                    >                      
                      {({ isActive }) => (
                        <button
                          type="button"
                          className={
                            isActive ? 'link-btn-active' : 'link-btn-inactive'
                          }
                        >
                          <div className="data">
                            <div className="imgPage">{item.img}</div>
                            <p>{item.label}</p>
                          </div>
                        </button>
                      )}
                    </NavLink>
                  ))}
                </div>
              </>
            );
          }}
        </NavLink>
      </div>
    </div>
  );
};

const SidebarNovo = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { email,setor } = useUsuarioLocal();
  const [filtro, setFiltro] = useState(''); // Estado para o filtro
  const [filterOpenSubmenu, setFilterOpenSubmenu] = useState(false)

  // Manipulador para atualizar o estado do filtro
  const handleFiltroChange = (e) => {
    // if(filtro.length< 2) {
    //   setFilterOpenSubmenu(false)
    // }
    // if(filtro !== "") {
    //   setFilterOpenSubmenu(true)
    // }
    setFilterOpenSubmenu(true)
    setFiltro(e.target.value);
  };


  return (
    <div className={`${isOpen ? 'sidebar-open-dad' : 'sidebar-close-dad'}`}>
      <div
        className={`sidebar-novo ${isOpen ? 'sidebar-open' : 'sidebar-close'}`}
        onMouseLeave={() => setIsOpen(!isOpen)}
      >
        {/* <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', bgcolor: 'rgba(246,202,161)', py: 2 }}>
          <img src={logoAmvox20} width={185} />
          {
            new Date().toLocaleString() <= "16/12/2023 10:30:00" ? (
              <>
                <Typography component="h6" variant="body1" textAlign={'center'} sx={{ color: '#000' }} fontWeight={'700'} >EVENTO:</Typography>
                <Typography component="h6" variant="body1" textAlign={'center'} sx={{ color: '#000' }} fontWeight={'700'} >16 DE DEZEMBRO</Typography>
                <Typography component="h6" variant="body1" textAlign={'center'} sx={{ color: '#000' }} >Espaço Paraíba &#x2022; <span style={{ fontWeight: '700' }}>10h30</span></Typography>
                <Typography component="h6" variant="body1" textAlign={'center'} sx={{ color: '#000' }} >Camaçari - BA</Typography>
              </>
            ) : (
              <></>
            )

          }
        </Box> */}
        <div
          id="header"
          className={`nav-height text-center p-0 d-flex align-items-center`}
        >
          <p className="text-center tamanhoDaFontedoEmail">
            {email}
            <br />
            <span onClick={logout} className="logout_sidebar">
              Sair
            </span>
          </p>
        </div>
        <Paper component={"form"} sx={{ width: '80%', display: 'flex', flexDirection: 'row', ml: 4 }}>
          <InputBase
            sx={{ ml: 2, flex: 1 }}
            placeholder="Pesquisar Página"
            inputProps={{ 'aria-label': 'pesquisar página' }}
            value={filtro}
            onChange={handleFiltroChange}
            size='small'
          />
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => { setFilterOpenSubmenu(false), setFiltro('') }}>
            {
              filtro !== '' ? <CloseIcon /> : <SearchIcon />

            }
          </IconButton>
        </Paper>
        <div id="links">
          <div className="menu">{ButtonsPagesPrincipal.map((item, index) => Menu(item, index, filtro, filterOpenSubmenu))}</div>
        </div>
      </div>
      <div className="buttonsMenuSidebar">
        <OverlayTrigger
          placement={'top'}
          overlay={
            <Tooltip>
              <strong>Menu</strong>
            </Tooltip>
          }
        >
          <div
            className={setor === 12 ? "clip-path-disabled" : "clip-path"}
            onClick={() => setIsOpen(!isOpen)}
            onMouseEnter={() => setIsOpen(!isOpen)}
            
          >
            <br />
            {isOpen ? (
              <IoMdArrowDropleft color="#ffffff" size={24} />
            ) : (
              <IoMdArrowDropright color="#ffffff" size={24} />
            )}
            <br />
            <br />
          </div>
        </OverlayTrigger>
      </div>
    </div>
  );
};

export default SidebarNovo;
