import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const GraficoDistribuicao = ({ data }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    series: [],
  });

  useEffect(() => {
    if (data && data.length > 0) {
      const labels = data.map((item) => item.categoria);
      const series = data.map((item) => item.participacao);
      setChartData({ labels, series });
    }
  }, [data]);

  const options = {
    labels: chartData.labels,
    colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#A55EEA'],
    legend: {
      position: 'bottom',
      horizontalAlign: 'center',
      floating: false,
      fontSize: '16px',
      itemMargin: {
        horizontal: 10,
        vertical: 5,
      },
      labels: {
        colors: '#000',
        useSeriesColors: false,
      },
    },
    dataLabels: {
      enabled: true,
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          chart: {
            width: '100%',
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      <div style={{ width: '100%', height: '100%' }}>
        <Chart
          options={options}
          series={chartData.series}
          type="donut"
          width="100%"
        />
      </div>
    </div>
  );
};

export default GraficoDistribuicao;
