import React from 'react';

import Rotas from '../src/rotas/routes';

import { CotacaoProvider } from './hooks/utils/cotacao.hook';
import { UsuarioProvider } from './hooks/usuarios/usuario.hook';
import { ToastProvider } from './hooks/components/toast.hook';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { IconContext } from 'react-icons/lib';
import AcessoRestritoTIProvider from './hooks/acesso-ti/acesso-restrito-ti.hook';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import NotaPdfFiscalProvider from './hooks/nota-fiscal-pdf/nota-fiscal-pdf.hook';
import '../src/libs/dayjs'

export default function App() {
  return (
    <ToastProvider>
      <IconContext.Provider value={{ className: 'react-icons' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>

          <CotacaoProvider>
            <UsuarioProvider>
              <AcessoRestritoTIProvider>
                <NotaPdfFiscalProvider>
                  <Rotas />
                </NotaPdfFiscalProvider>
              </AcessoRestritoTIProvider>
            </UsuarioProvider>
          </CotacaoProvider>
        </LocalizationProvider>

      </IconContext.Provider>
    </ToastProvider>
  );
}
