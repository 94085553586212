import React, { useState } from 'react'
import { GetChamadosSolicitantes } from '../../services/chamados/chamados.service';
import { useToast } from '../../hooks/components/toast.hook';
import useUsuarioLocal from '../../hooks/usuarioLocal.hook';

export function useFetchChamados() {
    const [removeLoading, setRemoveLoading] = useState(false);
    const [listaChamados, setListaChamados] = useState([])
    const { email } = useUsuarioLocal()
    const { addToast } = useToast()

    const handleFetch = () => {
        GetChamadosSolicitantes(email).then((res) => { setListaChamados(res); }).catch(() =>
          addToast({
            type: 'danger',
            title: 'Erro ao listar Chamados !'
          })
        ).finally(() => setRemoveLoading(true));
      }

  return {
    handleFetch, listaChamados, removeLoading
  }
}
