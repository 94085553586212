import React, { useState, useEffect } from 'react';
import LayoutNovo from '../../components/LayoutNovo';
import { LeitorApontamentosQRCode } from '../../services/leitorQRcode/leitorqrcode.Service';
import QrCodeIcon from '@mui/icons-material/QrCode';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback } from 'react';
import TitlePage from '../../components/TitlePage';
import debounce from '../../utils/debounce';
import { buscarGalpao } from '../../services/galpao/galpao.service';
import { useToast } from '../../hooks/components/toast.hook';
import { formatDateTime } from '../../utils/formatDateInput';
import { consultaProdutosImagem } from '../../services/produtos/produtos.service';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const linhasLista = [
  { label: '1' },
  { label: '2' },
  { label: '3' },
  { label: '4' },
  { label: '5' },
  { label: '6' },
  { label: '7' },
  { label: '8' },
  { label: '9' },
];

export default function LeitorQRCode() {
  const [openModal, setOpenModal] = useState(true);
  const [listaGalpao, setListaGalpao] = useState([]);
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState({
    qrcode: '',
    linha: 0,
    galpaoId: 0,
  });
  const [galpaoSelecionado, setGalpaoSelecionado] = useState('');
  const [resApontamento, setResApontamento] = useState([]);
  const [imagemProduto, setImagemProduto] = useState('');
  const [qrCodeValue, setQrCodeValue] = useState('');
  const [prevProduto, setPrevProduto] = useState('');

  const { addToast } = useToast();

  const handlePostQr = useCallback(() => {
    setLoading(true);
    LeitorApontamentosQRCode(item)
      .then((res) => {
        setResApontamento(res);
        addToast({
          type: 'success',
          description: res.message,
        });
      })
      .catch((_err) => {
        addToast({
          type: 'danger',
          title: 'Erro',
          description: _err.response.data.error,
        });
        setResApontamento(_err.response.data);
      })
      .finally(() => {
        setItem({ ...item, qrcode: '' }), setQrCodeValue(''), setLoading(false);
      });
  }, [item]);

  useEffect(() => {
    if (item.qrcode !== '') {
      handlePostQr();
    }
  }, [item]);

  useEffect(() => {
    buscarGalpao().then((res) => {
      res.map((item) =>
        setListaGalpao((oldState) => [
          ...oldState,
          { label: item.nome, value: item.galpaoId },
        ])
      );
    });
  }, []);

  useEffect(() => {
    if (resApontamento?.produto) {
      if (resApontamento.produto !== prevProduto) {
        consultaProdutosImagem({ apelido: resApontamento.produto }).then(
          (res) => {
            setImagemProduto(res[0].imagem);
          }
        );
        setPrevProduto(resApontamento.produto);
      }
    }
  }, [resApontamento.produto]);

  return (
    <LayoutNovo setorColor={'producao'}>
      <Box position={'relative'}>
        <Modal
          open={openModal}
          // onClose={() => setOpenModal(!openModal)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              textAlign={'center'}
            >
              SELECIONA A LINHA E O GALPÃO
            </Typography>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={linhasLista}
              fullWidth
              sx={{ mb: 1 }}
              renderInput={(params) => <TextField {...params} label="Linhas" />}
              onChange={(event, newValue) => {
                setItem({
                  ...item,
                  linha: parseInt(newValue?.label),
                });
              }}
            />
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={listaGalpao}
              fullWidth
              sx={{ mb: 1 }}
              renderInput={(params) => <TextField {...params} label="Galpão" />}
              onChange={(event, newValue) => {
                setItem({
                  ...item,
                  galpaoId: newValue?.value,
                });
                setGalpaoSelecionado(newValue?.label);
              }}
            />
            <Box display={'flex'} flexDirection={'column'}>
              <Button
                variant="contained"
                size="large"
                fullWidth
                disabled={item.linha === 0 || item.galpaoId === 0}
                onClick={() => setOpenModal(!openModal)}
              >
                Iniciar
              </Button>
              <Button variant="outlined" color="error" fullWidth>
                Cancelar
              </Button>
            </Box>
          </Box>
        </Modal>

        <TitlePage
          title={'Registro QRcode'}
          iconChildren={<QrCodeIcon size={100} color={'#FFFFFF'} />}
        />

        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
          <Box sx={{ flex: 3 }}>
            <Box
              sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}
              fullWidth
              mb={1}
            >
              <Box sx={{ flex: 4 }} mb={1} fullWidth>
                <Typography sx={{ bgcolor: '#F00', color: '#fff' }} px={1}>
                  PRODUTO
                </Typography>
                <Typography
                  sx={{ bgcolor: '#fff', color: '#000', fontSize: 24 }}
                  px={1}
                >
                  {resApontamento?.produto}
                </Typography>
              </Box>
              <Box sx={{ flex: 2 }} mb={1} fullWidth>
                <Typography sx={{ bgcolor: '#F00', color: '#fff' }} px={1}>
                  GALPÃO
                </Typography>
                <Typography
                  sx={{ bgcolor: '#fff', color: '#000', fontSize: 24 }}
                  px={1}
                >
                  {galpaoSelecionado}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}
              fullWidth
              mb={1}
            >
              {/* <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 1 }} fullWidth> */}
              {/* <Box>
                  <Typography sx={{ bgcolor: '#F00', color: '#fff' }}>PRODUTO ATUAL</Typography>
                  <Typography sx={{ bgcolor: '#fff', color: '#000' }}>VALORDOPRODUTO</Typography>
                </Box> */}
              <Box sx={{ flex: 4 }} fullWidth>
                <Typography
                  sx={{ bgcolor: '#F00', color: '#fff' }}
                  fullWidth
                  px={1}
                >
                  QRCODE
                </Typography>
                <TextField
                  autoFocus
                  sx={{ bgcolor: '#fff', color: '#000', fontSize: 50 }}
                  fullWidth
                  type="text"
                  value={qrCodeValue}
                  onChange={(e) => {
                    debounce(() => {
                      setItem({ ...item, qrcode: e.target.value });
                    }, 500),
                      setQrCodeValue(e.target.value);
                  }}
                />
              </Box>
              <Box sx={{ flex: 1 }} fullWidth>
                <Typography sx={{ bgcolor: '#F00', color: '#fff' }} px={1}>
                  LINHA
                </Typography>
                <Typography
                  sx={{ bgcolor: '#fff', color: '#000', fontSize: 36 }}
                  px={1}
                >
                  {item.linha.toString()}
                </Typography>
              </Box>
              <Box sx={{ flex: 1 }} fullWidth>
                <Typography sx={{ bgcolor: '#F00', color: '#fff' }} px={1}>
                  PRODUÇÃO
                </Typography>
                <Typography
                  sx={{ bgcolor: '#fff', color: '#000', fontSize: 36 }}
                  px={1}
                >
                  {resApontamento?.totalDaLinha}
                </Typography>
              </Box>

              {/* </Box> */}
            </Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ bgcolor: '#000', color: '#f00', fontSize: 24 }}
                  >
                    QRCode
                  </TableCell>
                  <TableCell
                    sx={{ bgcolor: '#000', color: '#f00', fontSize: 24 }}
                    align="right"
                  >
                    Código
                  </TableCell>
                  <TableCell
                    sx={{ bgcolor: '#000', color: '#f00', fontSize: 24 }}
                    align="right"
                  >
                    Horário
                  </TableCell>
                  <TableCell
                    sx={{ bgcolor: '#000', color: '#f00', fontSize: 24 }}
                    align="right"
                  >
                    Linha
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading && (
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" colSpan={4}>
                      <CircularProgress color="secondary" />
                    </TableCell>
                  </TableRow>
                )}
                {resApontamento?.apontamentos?.map((item) => (
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    key={item.qrCode}
                  >
                    <TableCell component="th" scope="row">
                      {item.qrCode}
                    </TableCell>
                    <TableCell align="right" sx={{ fontSize: 18 }}>
                      {' '}
                      {item.codigo}
                    </TableCell>
                    <TableCell align="right" sx={{ fontSize: 18 }}>
                      {' '}
                      {formatDateTime(item.dataProducao)}
                    </TableCell>
                    <TableCell align="right" sx={{ fontSize: 18 }}>
                      {' '}
                      {item.linha}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: 200,
              flex: 1,
            }}
          >
            <Typography sx={{ bgcolor: '#f00', color: '#fff', px: 1 }}>
              FOTO
            </Typography>
            <Box
              component="img"
              sx={{
                width: '100%',
                objectFit: 'cover',
                minHeight: 80,
              }}
              alt="Imagem do Produto"
              src={`data:image/png;base64,${imagemProduto}`}
            />
          </Box>
        </Box>
      </Box>
    </LayoutNovo>
  );
}
