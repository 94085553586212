import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import axios from 'axios';
import { errosRequest } from '../../../services/logMonitoramento.service';

const useStyles = makeStyles({
  table: {
    minWidth: 100,
    minHeight: 100,
    fontFamily: 'Poppins',
    fontSize: 12,
    fontStyle: 'normalize',
    fontWeight: 400,
    lineHeight: 'normal',
  },
});

const InterfacePropsError = {
  logType: 'Error',
};

export default function ErrosTable() {
  const classes = useStyles();

  const [data, setData] = useState([InterfacePropsError]);

  const handleFetchLogsErro = () => {
    errosRequest({ logType: 'Error' })
      .then((response) => {
        setData(response);
      })
      .catch((_err) => {
        console.log(_err);
      });
  };

  useEffect(() => {
    handleFetchLogsErro();
  }, []);

  return (
    <TableContainer>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Mensagem</TableCell>
            <TableCell align="left">Exceção</TableCell>
            <TableCell align="left">UserID</TableCell>
            <TableCell align="left">Endpoint</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.id}>
              <TableCell align="left">{row.message}</TableCell>
              <TableCell align="left">{row.exception}</TableCell>
              <TableCell align="left">{row.userId}</TableCell>
              <TableCell align="left">
                {row.method} {row.endpointRequest}
              </TableCell>
              {/* <TableCell align="left">{row.endpointRequest}</TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
