import { Box, Typography } from '@mui/material';
import React from 'react';

export default function TitlePageV3({ iconChildren, title }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        marginBottom: 0,
        fontFamily: 'Poppins, sans-serif',
        '@media (max-width: 600px)': {
          flexDirection: 'column',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 2,
          color: 'black',
          paddingLeft: '8px',
        }}
      >
        {iconChildren}
        <Typography
          variant="h5"
          sx={{
            fontFamily: 'Poppins, sans-serif',
            '@media (max-width: 600px)': {
              fontSize: '1.5rem',
            },
          }}
        >
          {title}
        </Typography>
      </Box>
    </Box>
  );
}
