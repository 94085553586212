import { useEffect, useState } from 'react';
import { apiFabrica } from '../../../services/apis';
import { useParams } from 'react-router';
import { gerarPdf } from '../../../utils/gerarPdf';
import { Container } from './styles';

import formatCnpj from '../../../utils/formatCnpj';
import formatDateTotvs from '../../../utils/formatDataTotvs';
import { formatNumeroNfe } from '../../../utils/formatNfeNumero';
import formatCEP from '../../../utils/formatCEP';
import { buscarNotaFiscalPDF } from '../../../services/notafiscal.service';
import { useToast } from '../../../hooks/components/toast.hook';
import { formatCurrencyBRLnocifr } from '../../../utils/formatCurrency';

import test from '../../../assets/amvoxlogosemritmopng.png';

const Linhas = (props) => {
  function formatCnpj(value) {
    value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1 $2 $3/$4-$5');
  }

  return (
    <div className="linha">
      <div className="data t-center br">{props.item?.prO_CODIGO}</div>
      <div className="data pl-1 br">{props.item?.prO_DESCRI}</div>
      <div className="data ncm t-center br">{props.item?.prO_NCM}</div>
      <div className="data pl-1 br"></div>
      <div className="data t-center br">{props.item?.nF_CFOP}</div>
      <div className="data pl-1 br">{props.item?.prO_UM}</div>
      <div className="data t-center br">{props.item?.nF_QUANT},0000</div>
      <div className="data t-right br">
        {formatCurrencyBRLnocifr(props.item?.nF_PRECO)}
      </div>
      <div className="data t-right br">
        {formatCurrencyBRLnocifr(props.item?.nF_VALBRUT)}
      </div>
      <div className="data t-right br">
        {formatCurrencyBRLnocifr(props.item?.nF_BASEICM)}
      </div>
      <div className="data t-right br">
        {formatCurrencyBRLnocifr(props.item?.nF_VALICMS)}
      </div>
      <div className="data t-center br">
        {formatCurrencyBRLnocifr(props.item?.nF_VALIPI)}
      </div>
      <div className="data t-right br">
        {formatCurrencyBRLnocifr(props.item?.nF_ALQICMS)}
      </div>
      <div className="data t-right">
        {Number(props.item?.nF_ALQIPI).toFixed(2)} %
      </div>
    </div>
  );
};

const NotaFiscalTemplate = () => {
  const { documento } = useParams();

  const { addToast } = useToast();

  const [dados, setDados] = useState({});
  const [barcode, setBarcode] = useState('');
  const [fatura, setFatura] = useState([]);
  const [itens, setItens] = useState([]);

  useEffect(() => {
    buscarNotaFiscalPDF(documento)
      .then((retorno) => {
        setDados(retorno);
        setItens(retorno.produtos);
        setFatura(retorno.fatura);
        setBarcode(
          `https://api.invertexto.com/v1/barcode?token=2710%7CwCYefeAd3tVeMTHCtOCorbnU1ptwLYBm&text=${dados.nF_CHAVE}&type=code128&font=0`
        );
      })
      .catch((_err) => {
        addToast({
          type: 'danger',
          title: 'Erro ao CARREGAR!',
          description:
            'Erro ao carregar DADOS da Nota Fiscal, por favor tente novamente dentre de instantes !',
        });
      });
  }, [documento]);

  return (
    <Container>
      <button
        onClick={() =>
          gerarPdf(
            document.getElementById('nfe'),
            formatNumeroNfe(dados.nF_NRO)
          )
        }
      >
        Gerar PDF
      </button>
      <div id="nfe" className="content" style={{ marginTop: '20px' }}>
        <div className="cabecalho-recebimento b">
          <div className="quadrado-recebimento">
            <div className="text-recebimento-reistar">
              <p>
                RECEBEMOS DE REISTAR INDUSTRIA E COMERCIO DE ELETRONICOS LTDA OS
                PRODUTOS CONSTANTES DA NOTA FISCAL INDICADA AO LADO
              </p>
            </div>
            <div className="text-recebimento-nfe">
              <p>NF-e</p>
            </div>
          </div>
          <div className="quadrado-recebimento2">
            <div className="bt">
              <p>DATA DE RECEBIMENTO</p>
            </div>
            <div className="br bl bt">
              <p>IDENTIFICAÇÃO E ASSINATURA DO RECEBEDOR</p>
            </div>
            <div className="pl-1">
              N {formatNumeroNfe(dados.nF_NRO)} <br />
              SÉRIE {dados.nF_SERIE}
            </div>
          </div>
        </div>
        <div className="cabecalho b">
          <div className="quadro-dados-emitente br t-left flex flex-c justify-center">
            <div>
              <div className="logo">
                <img className="logoImg" src={test} alt="logoimagem" />
              </div>

              <div className="razao-social-head mt-2">
                <p className="razao-social t-left">Identificação do emitente</p>
                <strong className="razaoSocial">{dados.amV_RAZAOSOCIAL}</strong>
              </div>

              <div className="endereco mt-1 t-left mb-2">
                <p className="logradouro">{dados.amV_ENDERECO}</p>
                <p className="bairro">
                  {dados.amV_BAIRRO}, CEP: {dados.amV_CEP}
                </p>
                <p className="municipio">
                  {dados.amV_MUNICIPIO}, Fone: {dados.amV_FONE}
                </p>
              </div>
            </div>
          </div>
          <div className="quadro-descricao-danfe br t-center  ">
            <div className="entrada-saida">
              <div className="opcoes">
                <strong className="danfe">DANFE</strong>
                <p className="danfe-legenda">
                  DOCUMENTO AUXILIAR DA NOTA FISCAL ELETRÔNICA
                </p>
                <div className="containerEntradaSaida">
                  <div className="entradaSaida">
                    <strong>0 - ENTRADA</strong>
                    <strong>1 - SAIDA</strong>
                  </div>
                </div>
                <strong className="entrada-saida-opcao b">1</strong>
              </div>
            </div>
            <div className="numero-nota">
              <strong className="numero">
                Nº {formatNumeroNfe(dados.nF_NRO)}
              </strong>
              <strong className="serie text-sm">SÉRIE: {dados.nF_SERIE}</strong>
              <strong className="text-sm">FOLHA: 1/1</strong>
            </div>
          </div>
          <div className="quadro-codigo-barras-chave t-center">
            <div className="codigo-barras flex align-center justify-center bb">
              <img alt="" src={barcode} />
            </div>
            <div className="chave-acesso">
              <strong className="label-demais-campos">chave de acesso </strong>
              <strong className="chave">{dados.nF_CHAVE}</strong>
            </div>
            <div
              className="infos-nota-fiscal-site t-center flex
                        align-center justify-center bt"
            >
              <strong>
                Consulta de autenticidade no portal da NF-e
                www.nfe.fazenda.gov.br/portal ou site da SEFAZ Autorizada
              </strong>
            </div>
          </div>
        </div>
        <div className="quadro-codigo-barras-dados row-h bb bl br">
          <div className="natureza-operacao quadro-formatacao br">
            <strong className="label">natureza da operação</strong>
            <p className="definicao">{dados.nF_NATOPER}</p>
          </div>
          <div className="protocolo-autorizacao quadro-formatacao t-center">
            <strong className="label">PROTOCOLO DE AUTORIZAÇÃO DE USO</strong>
            <p className="definicao">------</p>
          </div>
        </div>
        <div className="quadro-documentos row-h bb bl br">
          <div className="inscricao-estadual quadro-formatacao br">
            <strong className="label">inscrição estadual</strong>
            <p className="definicao">{dados.amV_INSC_EST}</p>
          </div>
          <div className="inscricao-estadual-2 quadro-formatacao br ">
            <strong className="label">INSC.EST.DO SUBST.TRIBUTÁRIO</strong>
            <p className="definicao">-----</p>
          </div>
          <div className="cnpj quadro-formatacao">
            <strong className="label">cnpj</strong>
            <p className="definicao">{formatCnpj(dados.amV_CNPJ)}</p>
          </div>
        </div>
        <div className="grupos">destinatário/remetente</div>
        <div className="quadro-destinatario-remetente row-h b">
          <div className="quadro-formatacao br">
            <strong className="label">nome/razão social</strong>
            <p className="definicao">{dados.clI_NOME}</p>
          </div>
          <div className="quadro-formatacao br">
            <strong className="label">cnpj/cpf</strong>
            <p className="t-center">{formatCnpj(dados.clI_CNPJ)}</p>
          </div>
          <div className="quadro-formatacao">
            <strong className="label">DATA DE EMISSÃO</strong>
            <p className="t-center">{formatDateTotvs(dados.nF_EMISSAO)}</p>
          </div>
        </div>
        <div className="quadro-endereco1 row-h bb bl br">
          <div className="logradouro quadro-formatacao br">
            <strong className="label">endereço</strong>
            <p className="definicao">{dados.clI_ENDERE}</p>
          </div>
          <div className="bairro quadro-formatacao br">
            <strong className="label">bairro</strong>
            <p className="bairro">{dados.clI_BAIRRO}</p>
          </div>
          <div className="cep quadro-formatacao br">
            <strong className="label">cep</strong>
            <p className="definicao">{formatCEP(dados.clI_CEP)}</p>
          </div>
          <div className="data-saida quadro-formatacao">
            <strong className="label">data saída/entrada</strong>
            <p className="t-center">{formatDateTotvs(dados.nF_ENTREGA)} ---</p>
          </div>
        </div>
        <div className="quadro-endereco2 row-h bb bl br">
          <div className="municipio quadro-formatacao br">
            <strong className="label">municipio</strong>
            <p className="definicao">{dados.clI_MUNIC}</p>
          </div>
          <div className="fone quadro-formatacao br">
            <strong className="label">fone/fax</strong>
            <p className="definicao">{dados.clI_TEL}</p>
          </div>
          <div className="uf quadro-formatacao br">
            <strong className="label">uf</strong>
            <p className="definicao">{dados.clI_UF}</p>
          </div>
          <div className="inscricao-estadual quadro-formatacao br">
            <strong className="label">inscricao estadual</strong>
            <p className="definicao">{dados.clI_INSC_EST}</p>
          </div>
          <div className="hora-saida quadro-formatacao">
            <strong className="label">hora de saída</strong>
            <p className="t-center">{dados.horaEmissao}</p>
          </div>
        </div>
        <div className="grupos">fatura</div>
        <div className="quadro-fatura-duplicata row-h b">
          {fatura.map((item) => (
            <div className="br faturaBloco">
              <p>{item.faT_PARCELA}</p>
              <p>{item.faT_VALOR}</p>
              <p>{item.faT_VENCTO}</p>
            </div>
          ))}
        </div>
        <div className="grupos">cálculo do imposto</div>
        <div className="quadro-calculo-impostos row-h tamanho-quadros b">
          <div className="quadro-formatacao br">
            <strong className="label">BASE DE CÁLCULO DO ICMS</strong>
            <p className="t-right">
              {formatCurrencyBRLnocifr(dados.nF_BASEICM)}
            </p>
          </div>
          <div className="quadro-formatacao br">
            <strong className="label">VALOR DO ICMS</strong>
            <p className="t-right">
              {formatCurrencyBRLnocifr(dados.nF_VALICMS)}
            </p>
          </div>
          <div className="quadro-formatacao BCIMSSUB br">
            <strong className="label">
              BASE DE CÁLCULO DO ICMS SUBSTITUIÇÃO
            </strong>
            <p className="t-right textSub">
              {formatCurrencyBRLnocifr(dados.nF_BRICMS)}
            </p>
          </div>
          <div className="quadro-formatacao br">
            <strong className="label">VALOR DO ICMS SUBSTITUIÇÃO</strong>
            <p className="t-right">
              {formatCurrencyBRLnocifr(dados.nF_ICMSRET)}
            </p>
          </div>
          <div className="quadro-formatacao">
            <strong className="label">VALOR TOTAL DOS PRODUTOS</strong>
            <p className="t-right">
              {formatCurrencyBRLnocifr(dados.nF_TOTITEM)}
            </p>
          </div>
        </div>
        <div className="quadro-frete tamanho-quadros row-h bb bl br">
          <div className="quadro-formatacao br">
            <strong className="label">VALOR DO FRETE</strong>
            <p className="t-right">
              {formatCurrencyBRLnocifr(dados.nF_VALFRET)}
            </p>
          </div>
          <div className="quadro-formatacao br">
            <strong className="label">VALOR DO seguro</strong>
            <p className="t-right">
              {formatCurrencyBRLnocifr(dados.nF_SEGURO)}
            </p>
          </div>
          <div className="quadro-formatacao br">
            <strong className="label">desconto</strong>
            <p className="t-right">
              {formatCurrencyBRLnocifr(dados.nF_DESCONTO)}
            </p>
          </div>
          <div className="quadro-formatacao br">
            <strong className="label">outras despesas acessórias</strong>
            <p className="t-right">
              {formatCurrencyBRLnocifr(dados.nF_DESPESA)}
            </p>
          </div>
          <div className="quadro-formatacao br">
            <strong className="label">VALOR DO ipi</strong>
            <p className="t-right">
              {formatCurrencyBRLnocifr(dados.nF_VALIPI)}
            </p>
          </div>
          <div className="quadro-formatacao">
            <strong className="label">VALOR total da nota</strong>
            <p className="t-right">
              {formatCurrencyBRLnocifr(dados.nF_VALBRUT)}
            </p>
          </div>
        </div>
        <div className="grupos">transportador/volumes transportados</div>
        <div className="quadro-transportador row-h tamanho-quadros b">
          <div className="quadro-formatacao br">
            <strong className="label">razão social</strong>
            <p className="definicao">{dados.transP_RZSOCIAL}</p>
          </div>
          <div className="quadro-formatacao br">
            <strong className="label">FRETE POR CONTA</strong>
            <p className="definicao">{dados.transP_FERPORCONTA}</p>
          </div>
          <div className="quadro-formatacao br">
            <strong className="label">código ant</strong>
            <p className="definicao">{dados.transP_ANTT}</p>
          </div>
          <div className="quadro-formatacao br">
            <strong className="label">placa do veículo</strong>
            <p className="definicao">{dados.transP_PLACA}</p>
          </div>
          <div className="quadro-formatacao br">
            <strong className="label">uf</strong>
            <p className="definicao">{dados.transP_ESTADO}</p>
          </div>
          <div className="quadro-formatacao">
            <strong className="label">cpf/cnpj</strong>
            <p>{formatCnpj(dados.transP_CNPJ)}</p>
          </div>
        </div>
        <div
          className="quadro-end-transportadora row-h tamanho-quadros br bl
                bb"
        >
          <div className="quadro-formatacao br">
            <strong className="label">endereço</strong>
            <p className="definicao">{dados.transP_ENDERECO}</p>
          </div>
          <div className="quadro-formatacao br">
            <strong className="label">municipio</strong>
            <p className="definicao">{dados.transP_MUNICIPIO}</p>
          </div>
          <div className="quadro-formatacao br">
            <strong className="label">uf</strong>
            <p className="definicao">{dados.transP_ESTADO}</p>
          </div>
          <div className="quadro-formatacao">
            <strong className="label">inscrição estadual</strong>
            <p className="definicao">{dados.transP_INCEST}</p>
          </div>
        </div>
        <div
          className="quadro-peso-medidas row-h tamanho-quadros br bl
                bb"
        >
          <div className="quadro-formatacao br">
            <strong className="label">quantidade</strong>
            <p className="definicao">{dados.transP_VOLUME1}</p>
          </div>
          <div className="quadro-formatacao br">
            <strong className="label">espécie</strong>
            <p className="definicao">{dados.transP_ESPECI1}</p>
          </div>
          <div className="quadro-formatacao br">
            <strong className="label">marca</strong>
            <p className="definicao">{dados.transP_MARCA}</p>
          </div>
          <div className="quadro-formatacao br">
            <strong className="label">numeração</strong>
            <p className="definicao">{dados.transP_NUMERACAO}</p>
          </div>
          <div className="quadro-formatacao br">
            <strong className="label">peso bruto</strong>
            <p className="definicao">
              {Number(dados.transP_PESOBRUTO).toFixed(2) + '0'}
            </p>
          </div>
          <div className="quadro-formatacao">
            <strong className="label">peso liquido</strong>
            <p className="definicao">
              {Number(dados.transP_PESOLIQUIDO).toFixed(2) + '0'}
            </p>
          </div>
        </div>
        <div className="grupos">dados do produto/serviços</div>
        <div className="tabela-produtos b">
          <div className="tabela">
            <div className="head t-center bb ">
              <div className="br flex align-center justify-center">
                <b>CÓDIGO PRODUTO</b>
              </div>
              <div className="br flex align-center justify-center">
                <b>DESCRIÇÃO DO PRODUTO/SERVIÇO</b>
              </div>
              <div className="br flex align-center justify-center">
                <b>NCM/SH</b>
              </div>
              <div className="br flex align-center justify-center">
                <b>CST</b>
              </div>
              <div className="br flex align-center justify-center">
                <b>CFOP</b>
              </div>
              <div className="br flex align-center justify-center">
                <b>UN</b>
              </div>
              <div className="br flex align-center justify-center">
                <b>QUANT.</b>
              </div>
              <div className="br flex align-center justify-center">
                <b>V.UNITÁRIO</b>
              </div>
              <div className="br flex align-center justify-center">
                <b>V.TOTAL</b>
              </div>
              <div className="br flex align-center justify-center">
                <b>BC.ICMS</b>
              </div>
              <div className="br flex align-center justify-center">
                <b>V.ICMS</b>
              </div>
              <div className="br flex align-center justify-center">
                <b>V.IPI</b>
              </div>
              <div className="br flex align-center justify-center">
                <b>A.ICMS</b>
              </div>
              <div className="flex align-center justify-center">
                <b>A.IPI</b>
              </div>
            </div>
            <div className="body">
              {itens.map((item, i) => (
                <Linhas key={i} item={item} />
              ))}
            </div>
          </div>
        </div>
        <div className="grupos">
          <div className="">CÁLCULO DO ISSQN</div>
        </div>
        <div className="quadro-fatura-duplicata2 row-h b">
          <div className="br flex justify-between">
            <strong className="label">Inscrição municipal</strong>
          </div>

          <div className="br">
            <strong className="label">Valor total dos serviços</strong>
          </div>
          <div className="br">
            <strong className="label">base de cálculo do ISSQN</strong>
          </div>
          <div>
            <strong className="label">Valor do ISSQN</strong>{' '}
          </div>
        </div>

        <div className="grupos">dados adicionais</div>
        <div className="quadro-dados-adicionais b">
          <div className="quadro-formatacao-texto br">
            <strong className="label">informaçôes complementares</strong>
            <p className="definicao ft5"></p>
          </div>
          <div className="quadro-formatacao-texto">
            <strong className="label">reservado ao fisco</strong>
            <p className="definicao"></p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default NotaFiscalTemplate;
