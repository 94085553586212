import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { endpointsRequest } from '../../../services/logMonitoramento.service';

const useStyles = makeStyles({
  table: {
    minWidth: 500,
    fontFamily: 'Poppins',
    fontSize: 12,
    fontStyle: 'normalize',
    fontWeight: 400,
    lineHeight: 'normal',
  },
});

const interfacePropsEndpoints = {
  endpoint: '/api/Equipe',
  qtdAcessos: 0,
};

export default function TopEndPointsTable() {
  const classes = useStyles();

  const [data, setData] = useState([interfacePropsEndpoints]);

  const handleFetchTopEndpoints = () => {
    endpointsRequest({ data })
      .then((response) => {
        setData(response);
      })
      .catch((_err) => {
        console.log(_err);
      });
  };

  useEffect(() => {
    handleFetchTopEndpoints();
  }, []);

  return (
    <TableContainer>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Endpoint</TableCell>
            <TableCell align="center">Requisições</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.endpoint}>
              <TableCell component="th" scope="data">
                {row.endpoint}
              </TableCell>
              <TableCell align="center">{row.qtdAcessos}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
