import React, { memo, useEffect, useState, useCallback } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { useMemo } from 'react';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { IconButton, Tooltip, TextField } from '@mui/material';
import { Form, Modal, InputGroup, Button } from 'react-bootstrap';
import { apiFabrica } from '../../../services/apis';
import EditIcon from '@mui/icons-material/Edit';
import { useToast } from '../../../hooks/components/toast.hook';
import '../styles.css';

function TabelaRecebimentoProps({ listaRecebimento, handleFetch }) {
  const [showModal, setShowModal] = useState(false);
  const [novoStatus, setNovoStatus] = useState('');
  const [itemSelecionado, setItemSelecionado] = useState('');
  const { addToast } = useToast();

  const AtualizarStatus = async (idSelecionado, novoStatusSelecionado) => {
    try {
      await apiFabrica.put(
        `/RecebimentoPermanenciaContainer?id=${idSelecionado}&status=${novoStatusSelecionado}`
      );
      handleClose();
      handleFetch();
    } catch (err) {
      addToast({
        type: 'warning',
        title: 'Erro ao atualizar status!',
      });
    } finally {
      addToast({
        type: 'success',
        title: 'Sucesso ao atualizar status!',
      });
    }
  };

  const handleClose = () => {
    setShowModal(!showModal);
    setNovoStatus('');
    setItemSelecionado('');
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'inicio_op',
        header: 'Hora De Chegada',
        size: 'small',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'final_op',
        header: 'Hora Da Finalização',
        size: 'small',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'chegada',
        header: 'HoraRec Logist',
        size: 'small',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'doca',
        header: 'Doca',
        size: 'small',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
        Cell: ({ cell }) => {
          return <b>{cell.getValue()}</b>;
        },
      },
      {
        accessorKey: 'motorista',
        header: 'Motorista',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'declaracao_importacao',
        header: 'D.I',
        size: 'small',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'bl',
        header: 'BL',
        size: 'small',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'container',
        header: 'CNTR',
        size: 'small',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'transportadora',
        header: 'Transporte',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'status',
        header: 'Status',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        header: 'Ações',
        Cell: ({ cell }) => {
          return (
            <IconButton
              aria-label="edit"
              onClick={(e) => {
                setItemSelecionado(cell.row.original.id);
                setShowModal(!showModal);
              }}
            >
              <EditIcon />
            </IconButton>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <Modal size="lg" show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Status</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ margin: 'auto' }}>
          <TextField
            label="Status"
            type="text"
            onChange={(e) => {
              setNovoStatus(e.target.value);
            }}
          />
        </Modal.Body>
        <div
          style={{
            margin: 'auto',
          }}
        >
          <Button
            style={{
              backgroundColor: 'green',
              color: 'white',
              width: '200px',
              marginTop: '20px',
              marginBottom: '20px',
            }}
            className="ms-2"
            variant="contained"
            size="small"
            onClick={() => {
              AtualizarStatus(itemSelecionado, novoStatus);
            }}
          >
            Salvar
          </Button>
        </div>
      </Modal>

      <MaterialReactTable
        data={listaRecebimento}
        columns={columns}
        enableColumnFilters={false}
        enableStickyHeader
        sx={{
          '& .MuiDataGrid-row:hover': {
            backgroundColor: 'inherit',
          },
        }}
        initialState={{ density: 'compact', pagination: { pageSize: 5 } }}
        muiTableContainerProps={{ sx: { maxHeight: 600 } }}
        muiTopToolbarProps={{ sx: { bgcolor: '#56A0F5', color: 'white' } }}
        muiTableHeadCellProps={{
          sx: {
            backgroundColor: 'white',
            color: 'black',
          },
        }}
        muiTableBodyProps={{
          sx: {
            '& tr:nth-of-type(odd)': {
              backgroundColor: 'white',
            },
            '& tr:nth-of-type(even)': {
              backgroundColor: '#ECECEC',
            },
          },
        }}
        localization={MRT_Localization_PT_BR}
      />
    </>
  );
}

export const TabelaRecebimento = memo(
  TabelaRecebimentoProps,
  (prevProps, nextProps) => {
    Object.is(prevProps, nextProps);
  }
);
