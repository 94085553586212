import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin: 0.2rem 0px;
  display: flex;
  /* flex-direction: row-reverse; */
  justify-content: center;
  flex-direction: column;
  position: relative;
  border-radius: 10px;
  border: solid 2px #d2d2d2;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.33) 2px 2px 4px -8px;
  font-family: 'Roboto', sans-serif;
  height: 100%;
  min-height: 100px;
  position: relative;
  background: ${(props) => props.color};
  filter: ${(props) => props.filterBlur ? 'blur(5px)' : 'blur(0px)'};
  -webkit-filter: ${(props) => props.filterBlur ? 'blur(5px)' : 'blur(0px)'};
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-top: 2rem;
  padding-bottom: 1rem;


  .spinner {
    color: #fff;
    position: absolute;
    left: 40%;
  }
  .loading{
    color: #fff;
    font-size: ${(props) => props.cardGraphic ? '155%' : '75%'};
    font-weight: 700;
    text-align: center;
  }
  .title {
    color: #fff;
    background: ${(props) => props.color};
    filter: saturate(02);
    position: absolute;
    /* filter: contrast(80); */
    top: 0;
    z-index: 2;
    font-size: ${(props) => props.cardGraphic ? '155%' : '75%'};
    font-weight: 700;
    /* font-family: 'Inter'; */
    text-align: center;
    /* display: flex; */
    width: 100%;
  }

  .loader {
    color: #ffff;
  }

  > img {
    height: 85%;

    position: absolute;
    top: -10px;
    left: -30px;

    opacity: 0.1;
  }

  .content {
    z-index: 4;

    .value {
      color: #ffffff;
      margin: ${(props) => props.cardGraphic ? '20px 0 0 0' : '0px'};
      font-weight: 700;
      font-size: ${(props) => props.cardGraphic ? '220%' : '100%'};
      text-align: center;
    }
  }
  .describe_link {
    background-color: #000;
    position: absolute;
    bottom: 0;
    z-index: 4;
    width: 100%;
    padding-top: .2rem;
    opacity: 0;
    transform: translateY(-5px);
    -webkit-transform: translate(-5px);
    transition: .2s ease-in-out;

    p {
      text-align: center;
      color: #ff0;
    }
  }

  &:hover {
    .describe_link {
      transition: .2s ease-in-out;
      transform: translateY(5px);
      -webkit-transform: translate(-5px);
      opacity: 1;
    }
  }
  @media (max-width: 1440px) {
    justify-content: space-between;

  }

  @media (max-width: 700px) {
    .title {
      font-size: ${(props) => props.cardGraphic ? '100%' : '75%'};

    }
  }
`;

export const BoxVariantCurrent = styled.div`
  display: flex;
  margin: auto;
  width: max-content;
  justify-content: center;
  align-items: center;
  padding: 0.15rem 1.2rem;
  gap: ${(props) => props.cardGraphic ? '1rem' : '0rem'};
  font-size: ${(props) => props.cardGraphic ? '1.6rem' : '0.8rem'};
  background: rgba(0, 0, 0, 0.9);
  flex-direction: ${(props) => props.cardGraphic ? 'row' : 'column'};
  border-radius: 10px;

  p {
    margin: 0;
    color: #fff;
  }
`;

export const FooterCard = styled.p`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translate(-50%);
  background: ${(props) => props.color};
  filter: saturate(02);
  z-index: 2;
  color: #ffffff;
  border-radius: 10px 10px 0 0;
  width: max-content;
  min-width: 150px;
  margin: 0 auto;
  padding: .15rem 0.8rem;
  -webkit-box-shadow: 0px -1px 10px -2px rgba(0,0,0,0.28);
  -moz-box-shadow: 0px -1px 10px -2px rgba(0,0,0,0.28);
  box-shadow: 0px -1px 10spx -2px rgba(0,0,0,0.28);

  text-align: center;
  font-size: ${(props) => props.cardGraphic ? '1rem' : '0.8rem'};
`;

export const Graphic = styled.div`
  margin: 0 auto;
  margin-bottom: .5rem;
`;

export const ContainerDesativado = styled.div`
  width: 100%;
  height: 100%;
  background-color: #c3c3c3;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: solid 2px #d2d2d2;
  margin: 0.2rem 0px;

  > img {
    height: 85%;

    position: absolute;

    opacity: 0.05;
  }

  .description {
    text-align: center;
    color: #0000;
    font-weight: bold;
  }
`;