import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import {
  Button,
  Box,
  Modal,
  TextField,
  Typography,
  FormControl,
  Grid,
} from '@mui/material/';
import '../styles.css';
import {
  UpdateHora,
  buscaApontamentosId,
} from '../../../services/apontamentosTi.service';

import DataSaverOnIcon from '@mui/icons-material/DataSaverOn';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { formatDateVisual } from '../../../utils/formatDateInput';
import { useToast } from '../../../hooks/components/toast.hook';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: '90%',
    sm: '80%',
    md: '70%',
    lg: '60%',
    xl: '50%',
  },
  maxWidth: '600px',
  maxHeight: '700px',
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 3,
  overflow: 'scroll',
  overflowX: 'hidden',
};

const interfacePatch = {
  idApontamentoInteligencia: 0,
  idUsuario: 0,
  hr_Inicio: null,
  hr_Final: null,
  numChamado: 0,
  tarefa_Demanda: '',
  slA_ID: 0,
  observacao: '',
};

export default function ModalFechar({ nome, data, handleFetchApontamentos }) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const [dataFim, setDataFim] = useState(data);

  const { addToast } = useToast();

  const inputTextHandler = (e) => {
    const { name, value } = e.target;
    setDataFim({
      ...dataFim,
      [name]: value,
      idApontamentoInteligencia: dataFim.id,
      idUsuario: dataFim.colaboradorID,
    });
  };

  const handleSubmit = useCallback(() => {
    UpdateHora(dataFim)
      .then((res) => {
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Apontamento final atualizado',
        });
        handleFetchApontamentos();
        setDataFim(interfacePatch);
        handleGetId();
        handleClose();
      })
      .catch((_err) => {
        addToast({
          type: 'danger',
          title: 'Erro',
          description: 'Erro ao atualizar',
        });
      });
  });

  const handleGetId = () => {
    buscaApontamentosId(dataFim.id).then((response) => {
      setDataFim(response);
    });
  };

  return (
    <div>
      <Button
        onClick={() => {
          handleOpen();
          handleGetId();
        }}
      >
        <DataSaverOnIcon sx={{ color: '#1e4572' }} />
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Box
            className="poppins-bold"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Atualizar Apontamento
          </Box>
          <Box>
            <Grid
              container
              spacing={2}
              columns={16}
              sx={{
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <Grid item xs={8}>
                <Typography variant="h5" mt={3}>
                  Data Inicial
                </Typography>
                <FormControl>
                  <TextField
                    sx={{ mt: 2 }}
                    id="hr_Inicio"
                    name="hr_Inicio"
                    type="datetime-local"
                    defaultValue={dataFim.hr_Inicio}
                    value={dataFim.hr_Inicio}
                    variant="outlined"
                    onChange={inputTextHandler}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h5" mt={3}>
                  Data Final
                </Typography>
                <FormControl>
                  <TextField
                    id="hr_Final"
                    name="hr_Final"
                    type="datetime-local"
                    defaultValue={dataFim.hr_Final}
                    value={dataFim.hr_Final}
                    onChange={inputTextHandler}
                    sx={{ mt: 2 }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Typography variant="h5" mt={3}>
              Atividade
            </Typography>
            <Box
              sx={{
                flexDirection: 'row',
                display: 'flex',
                display: 'inline-flex',
                width: '100%',
                mt: 1,
              }}
            >
              <Grid
                container
                spacing={2}
                columns={16}
                sx={{
                  justifyContent: 'space-between',
                  display: 'flex',
                }}
              >
                <Grid item xs={5}>
                  <FormControl>
                    <Box className="poppins-regular-card" sx={{ padding: 1 }}>
                      Num. Chamado
                    </Box>
                    <TextField
                      id="numChamado"
                      name="numChamado"
                      type="number"
                      value={dataFim.numChamado}
                      defaultValue={dataFim.numChamado}
                      onChange={inputTextHandler}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <FormControl sx={{ display: 'flex', width: '100%' }}>
                    <Box
                      maxWidth
                      className="poppins-regular-card"
                      sx={{ padding: 1, width: '100%' }}
                    >
                      Descrição da Tarefa
                    </Box>
                    <TextField
                      id="tarefa_Demanda"
                      name="tarefa_Demanda"
                      defaultValue={dataFim.tarefa_Demanda}
                      value={dataFim.tarefa_Demanda}
                      onChange={inputTextHandler}
                      maxWidth
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>

            <Typography variant="h5" mt={2}>
              Observação
            </Typography>
            <Box>
              <FormControl sx={{ width: '100%' }}>
                <TextField
                  id="observacao"
                  name="observacao"
                  value={dataFim.observacao}
                  defaultValue={dataFim.observacao}
                  onChange={inputTextHandler}
                  sx={{ mt: 2 }}
                  fullWidth
                />
              </FormControl>
            </Box>
          </Box>
          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 2,
              color: 'red',
            }}
          >
            {nome}
          </Typography>
          <Box sx={{ display: 'flex', gap: 2, paddingY: 1, mt: 2 }} fullWidth>
            <Button
              variant="contained"
              color="success"
              endIcon={<ArrowOutwardIcon />}
              fullWidth
              onClick={() => {
                handleSubmit();
              }}
            >
              Enviar
            </Button>
            <Button
              variant="contained"
              color="error"
              endIcon={<DeleteOutlineIcon />}
              fullWidth
              type="reset"
              onClick={handleClose}
            >
              Cancelar
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
