import React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import formatDateTotvs from '../../../utils/formatDataTotvs';
import CircularProgressWithColor from '../../../components/CircularProgressWithColor';
import RegistrarQuantidadeProduzida from './ModalQTProduzido';

function Row(props, handleAtt) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="right">{row.semana_Ano}</TableCell>
        <TableCell>{formatDateTotvs(row.periodoInicio)}</TableCell>
        <TableCell>{formatDateTotvs(row.periodoFim)}</TableCell>
        <TableCell align="right">{row.quantidadeProgramacao}</TableCell>
        <TableCell align="right">{row.horasNecessarias}</TableCell>
        <TableCell align="right">{row.horasdisponiveis}</TableCell>
        <TableCell align="right">{row.capacidadeutilizada}</TableCell>
        <TableCell align="right">{row.horasDisponiveisDiariamente}</TableCell>
        <TableCell align="right">{row.linhasDisponiveis}</TableCell>
        <TableCell align="right">{row.diasDisponiveis}</TableCell>
        <TableCell align="right">{row.quantidadeProduzida}</TableCell>
        <TableCell align="right">{row.observacao || '-'}</TableCell>
        <TableCell align="right">
          <CircularProgressWithColor
            value={row.porcentagemExecutado}
            cor="#219C90"
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={14}
          style={{
            padding: 0,
            backgroundColor: '#F4F4F4',
          }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ padding: 2 }}>
              <Typography variant="h6">Produtos</Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>Código</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      Descrição
                    </TableCell>
                    <TableCell align="right" style={{ fontWeight: 'bold' }}>
                      Peças/Hora
                    </TableCell>
                    <TableCell align="right" style={{ fontWeight: 'bold' }}>
                      Total horas necessárias
                    </TableCell>
                    <TableCell align="right" style={{ fontWeight: 'bold' }}>
                      Prioridade
                    </TableCell>
                    <TableCell align="right" style={{ fontWeight: 'bold' }}>
                      Qtd Planejada
                    </TableCell>
                    <TableCell align="right" style={{ fontWeight: 'bold' }}>
                      Saldo Pendente
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      Qtd Realizada
                    </TableCell>
                    <TableCell align="right" style={{ fontWeight: 'bold' }}>
                      Nº OP
                    </TableCell>
                    <TableCell align="right" style={{ fontWeight: 'bold' }}>
                      Observações
                    </TableCell>
                    <TableCell align="right" style={{ fontWeight: 'bold' }}>
                      Pendência
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.detalhes.map((detalhes, index) => (
                    <TableRow key={index}>
                      <TableCell>{detalhes.codigo || '-'}</TableCell>
                      <TableCell>{detalhes.descricaoProduto || '-'}</TableCell>
                      <TableCell align="right">
                        {detalhes.metaHora || '-'}
                      </TableCell>
                      <TableCell align="right">
                        {detalhes.horasNecessarias || '-'}
                      </TableCell>
                      <TableCell align="right">
                        {detalhes.prioridade || '-'}
                      </TableCell>
                      <TableCell align="right">
                        {detalhes.qtdPendente || '-'}
                      </TableCell>
                      <TableCell align="right">
                        {detalhes.saldoPendente || '-'}
                      </TableCell>
                      <TableCell align="center">
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          {detalhes.qtdRealizada}
                          <RegistrarQuantidadeProduzida
                            value={detalhes.qtdRealizada}
                            id={detalhes.id}
                            prioridade={detalhes.prioridade}
                            codigo={detalhes.codigo}
                            observacao={detalhes.observacao}
                            handlePut={handleAtt}
                          />
                        </Box>
                      </TableCell>
                      <TableCell align="right">{detalhes.op || '-'}</TableCell>
                      <TableCell align="right">
                        {detalhes.observacao || '-'}
                      </TableCell>
                      <TableCell align="right">
                        {detalhes.pendencia || '-'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function TabelaPlanejamento({ listaPlanejamento, handleAtt }) {
  return (
    <TableContainer
      component={Paper}
      sx={{
        maxHeight: 540,
        overflowY: 'auto',
        borderRadius: '16px',
      }}
    >
      <Table aria-label="collapsible table" sx={{ bgcolor: '#F4F4F4' }}>
        <TableHead sx={{ bgcolor: '#F4F4F4' }}>
          <TableRow sx={{ bgcolor: '#F4F4F4' }}>
            <TableCell />
            <TableCell style={{ fontWeight: 'bold' }} align="right">
              Semanas
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align="right">
              Periodo Início
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align="right">
              Periodo Fim
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align="right">
              Quantidade Programada
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align="right">
              Hrs Prod. Necessarias
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align="right">
              Hrs. Disp
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align="right">
              Capacidade Utilizada
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align="right">
              Horas Disponíveis/Dia
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align="right">
              Linhas Disponíveis
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align="right">
              Dias Disponíveis
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align="right">
              Quantidade Produzida
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align="right">
              Observação
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align="right">
              Porcentagem(%)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            bgcolor: '#FFF',
          }}
        >
          {Array.isArray(listaPlanejamento) &&
            listaPlanejamento.map((row) => (
              <Row key={row.id} row={row} handleAtt={handleAtt} />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
