export default [
  {
    nome: 'Setor',
    valor: 0,
  },
  {
    nome: 'COMERCIAL',
    valor: 1,
  },
  {
    nome: 'DIRETORIA',
    valor: 2,
  },
  {
    nome: 'ESTOQUE',
    valor: 3,
  },
  {
    nome: 'FINANCEIRO',
    valor: 4,
  },
  {
    nome: 'FISCAL',
    valor: 5,
  },
  {
    nome: 'GESTÃO TALENTOS - R.H',
    valor: 6,
  },
  {
    nome: 'INTELIGÊNCIA - T.I',
    valor: 7,
  },
  {
    nome: 'IMPORTAÇÃO',
    valor: 8,
  },
  {
    nome: 'TRANSPORTE',
    valor: 9,
  },
  {
    nome: 'PRODUÇÃO',
    valor: 10,
  },
  { 
    nome: 'ATENDIMENTO',
    valor: 11,
  },
  {
    nome: 'PROMOTORAS',
    valor: 12,
  },
  {
    nome: 'TECNICOS - PRODUTOS',
    valor: 13,
  },
  {
    nome: 'SECRETARIADO',
    valor: 14,
  },
  {
    nome: 'PORTARIA',
    valor: 15,
  },
  {
    nome: 'INOVAÇÃO',
    valor: 16,
  },
  {
    nome: 'FACILITIES',
    valor: 17,
  },
  {
    nome: 'COMPRAS',
    valor: 18,
  },
  {
    nome: 'QUALIDADE',
    valor: 19,
  },
  {
    nome: 'LIMPEZA',
    valor: 20,
  },
  {
    nome: 'QCOMPRA',
    valor: 21,
  },
  {
    nome: 'TEC. DE SEGURANÇA',
    valor: 22,
  },
  {
    nome: 'RECEPÇÃO',
    valor: 23,
  },
  {
    nome: 'MARKETING',
    valor: 24,
  },
  {
    nome: 'CONTROLADORIA',
    valor: 25,
  },
  {
    nome: 'CONTABILIDADE',
    valor: 26,
  },
  {
    nome: 'DEPARTAMENTO PESSOAL',
    valor: 27,
  },
  {
    nome: 'LOG. REVERSA',
    valor: 28,
  },
];

export const setorSelect = [
  {
    nome: 'Setor',
    valor: 0,
  },
  {
    nome: 'COMERCIAL',
    valor: 1,
  },
  {
    nome: 'DIRETORIA',
    valor: 2,
  },
  {
    nome: 'ESTOQUE',
    valor:3,
  },
  {
    nome: 'FINANCEIRO',
    valor: 4,
  },
  {
    nome: 'FISCAL',
    valor: 5,
  },
  {
    nome: 'GESTÃO TALENTOS - R.H',
    valor: 6,
  },
  {
    nome: 'INTELIGÊNCIA - T.I',
    valor: 7,
  },
  {
    nome: 'IMPORTAÇÃO',
    valor: 8,
  },
  {
    nome: 'TRANSPORTE',
    valor: 9,
  },
  {
    nome: 'PRODUÇÃO',
    valor: 10,
  },
  {
    nome: 'ATENDIMENTO',
    valor: 11,
  },
  {
    nome: 'PROMOTORAS',
    valor: 12,
  },
  {
    nome: 'TECNICOS - PRODUTOS',
    valor: 13,
  },
  {
    nome: 'SECRETARIADO',
    valor: 14,
  },
  {
    nome: 'PORTARIA',
    valor: 15,
  },
  {
    nome: 'INOVAÇÃO',
    valor: 16,
  },
  {
    nome: 'FACILITIES',
    valor: 17,
  },
  {
    nome: 'COMPRAS',
    valor: 18,
  },
  {
    nome: 'QUALIDADE',
    valor: 19,
  },
  {
    nome: 'LIMPEZA',
    valor: 20,
  },
  {
    nome: 'QCOMPRA',
    valor: 21,
  },
  {
    nome: 'TEC. DE SEGURANÇA',
    valor: 22,
  },
  {
    nome: 'RECEPÇÃO',
    valor: 23,
  },
  {
    nome: 'MARKETING',
    valor: 24,
  },
  {
    nome: 'CONTROLADORIA',   
    valor: 25,
  },
  {
    nome: 'CONTABILIDADE',   
    valor: 26,
  },
  {
    nome: 'DEPARTAMENTO PESSOAL',   
    valor: 27,
  },
  {
    nome: 'LOG. REVERSA',   
    valor: 28,
  },
];