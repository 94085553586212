import * as React from 'react';
import Box from '@mui/material/Box';

import '../styles.css';

import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import {
  Grid,
  TextField,
  Modal,
  Typography,
  Button,
  FormControl,
} from '@mui/material';
import { useState, useCallback } from 'react';
import { registrarApontamentoInicial } from '../../../services/apontamentosTi.service';
import { formatDateVisual } from '../../../utils/formatDateInput';
import useUsuarioLocal from '../../../hooks/usuarioLocal.hook';

import { useToast } from '../../../hooks/components/toast.hook';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: '90%',
    sm: '80%',
    md: '70%',
    lg: '60%',
    xl: '50%',
  },
  maxWidth: '600px',
  maxHeight: '700px',
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 3,
  overflow: 'scroll',
  overflowX: 'hidden',
};

const interfacePost = {
  idApontamentoInteligencia: 0,
  idUsuario: 0,
  hr_Inicio: null,
  hr_Final: null,
  numChamado: 0,
  tarefa_Demanda: '',
  slA_ID: 0,
  observacao: '',
};

export default function ModalCadastroInicial({
  nome,
  handleFetchApontamentos,
  idUsuario,
}) {
  const { id } = useUsuarioLocal();
  const [open, setOpen] = React.useState(false);
  const [sendApontamento, setSendApontamento] = useState(interfacePost);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { addToast } = useToast();

  const inputTextHandler = (e) => {
    const { name, value } = e.target;
    setSendApontamento({ ...sendApontamento, [name]: value, idUsuario: id });
  };

  const handleSubmit = useCallback(() => {
    registrarApontamentoInicial(sendApontamento)
      .then((res) => {
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Apontamento cadastrado',
        });
        handleFetchApontamentos();
        setSendApontamento(interfacePost);
        handleClose();
      })
      .catch((_err) => {
        addToast({
          type: 'danger',
          title: 'Erro',
          description: 'Erro ao cadastrar',
        });
      });
  });

  return (
    <div>
      <Button
        sx={{ maxWidth: '425px', width: '100%', bgcolor: '#1e4572' }}
        variant="contained"
        onClick={handleOpen}
      >
        Apontamento
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            className="poppins-bold"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Cadastro Inicial
          </Box>
          <Box>
            <Grid
              container
              spacing={2}
              columns={16}
              sx={{
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <Grid item xs={8}>
                <Typography variant="h5" mt={3}>
                  Data Inicial
                </Typography>
                <FormControl>
                  <TextField
                    sx={{ mt: 2 }}
                    id="hr_Inicio"
                    name="hr_Inicio"
                    type="datetime-local"
                    value={formatDateVisual(sendApontamento.hr_Inicio)}
                    variant="outlined"
                    onChange={inputTextHandler}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h5" mt={3}>
                  Data Final
                </Typography>
                <FormControl>
                  <TextField
                    id="hr_Final"
                    name="hr_Final"
                    type="datetime-local"
                    value={formatDateVisual(sendApontamento.hr_Final)}
                    onChange={inputTextHandler}
                    sx={{ mt: 2 }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Typography variant="h5" mt={3}>
              Atividade
            </Typography>
            <Box
              sx={{
                flexDirection: 'row',
                display: 'flex',
                display: 'inline-flex',
                width: '100%',
                mt: 1,
              }}
            >
              <Grid
                container
                spacing={2}
                columns={16}
                sx={{
                  justifyContent: 'space-between',
                  display: 'flex',
                }}
              >
                <Grid item xs={5}>
                  <FormControl>
                    <Box className="poppins-regular-card" sx={{ padding: 1 }}>
                      Num. Chamado
                    </Box>
                    <TextField
                      id="numChamado"
                      name="numChamado"
                      type="number"
                      onChange={inputTextHandler}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <FormControl sx={{ display: 'flex', width: '100%' }}>
                    <Box
                      maxWidth
                      className="poppins-regular-card"
                      sx={{ padding: 1, width: '100%' }}
                    >
                      Descrição da Tarefa
                    </Box>
                    <TextField
                      id="tarefa_Demanda"
                      name="tarefa_Demanda"
                      onChange={inputTextHandler}
                      maxWidth
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>

            <Typography variant="h5" mt={2}>
              Observação
            </Typography>
            <Box>
              <FormControl sx={{ width: '100%' }}>
                <TextField
                  id="observacao"
                  name="observacao"
                  onChange={inputTextHandler}
                  sx={{ mt: 2 }}
                  fullWidth
                />
              </FormControl>
            </Box>
          </Box>
          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 3,
              color: 'red',
              fontSize: '15px',
            }}
          >
            {nome}
          </Typography>
          <Box sx={{ display: 'flex', gap: 2, paddingY: 1, mt: 2 }} fullWidth>
            <Button
              variant="contained"
              color="success"
              endIcon={<ArrowOutwardIcon />}
              fullWidth
              onClick={() => {
                handleSubmit();
              }}
            >
              Enviar
            </Button>
            <Button
              variant="contained"
              color="error"
              endIcon={<DeleteOutlineIcon />}
              fullWidth
              type="reset"
              onClick={handleClose}
            >
              Cancelar
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
