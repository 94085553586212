import { Box, Typography } from '@mui/material'
import React from 'react'

export default function TitlePage({ iconChildren, title }) {
    return (
        <Box sx={{
            display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between', marginBottom: 0, '@media (max-width: 600px)': {
                flexDirection: 'column',
            }
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2, textShadow: '-1px -1px 6px #000000', color: 'rgb(248, 249, 250)', p: [2] }}>
                {iconChildren}
                <Typography variant='h4' sx={{ '@media (max-width: 600px)': {
                fontSize: '1.5rem',
            } }}>{title}</Typography>
            </Box>
            {/* <Button variant="contained" color='error' sx={{
                '@media (max-width: 600px)': {
                    width: '90%',
                }
            }} startIcon={<AddIcon />} onClick={handleOpen}>Abrir Chamado</Button> */}
        </Box>)
}
