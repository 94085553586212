import { Box, Modal, Typography, Button } from "@mui/material";
import { useState } from "react";
import CardDetalheSolicitanteX from "../CardDetalheSolicitante/Xindex";
import ButtonCloseModal from "../../../components/ButtonCloseModal";
import ModalAdicaoDetalhes from "../ModalAdicaoDetalhe";
import LoupeIcon from '@mui/icons-material/Loupe';

const style = {
  position: 'absolute',
  display: 'flex',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '70%',
  height: '80%',
  backgroundColor: 'White',
  border: '2px solid #000',
  boxShadow: 24, 
  p: 4,
  display: 'flex',
  gap: 1,
  flexDirection: 'column',
};

export default function ModalDetalhesX({ open, OnClose, listaChamados, chamadoDescricao, chamadoTitulo, idChamadoSelecionado }) {

  const [openModal, setOpenModal] = useState(false);

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const cancelFormData = () => {
    OnClose();
  }

  const titulo = {
    fontWeight: 'bold',
  }

  return (
    <>
      <ModalAdicaoDetalhes
        openModal={openModal}
        handleModal={handleModal}
        idChamadoSelecionado={idChamadoSelecionado}
        sx={style}
      />
      <Modal
        open={open}
        onClose={handleModal} // Use onClose em vez de onHide
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1300, // Garante que o modal fique no topo
        }}
      >
        <Box sx={style}>
          <ButtonCloseModal onClick={cancelFormData} />

          <Box sx={{ height: '10%', border: '1px solid #000000', backgroundColor: '#e8e8e8', width: '95%' }}>
            <Typography

            > <span style={titulo}> TÍTULO:
              </span>  {chamadoTitulo} </Typography>
          </Box>
          <Box sx={{ height: '10%', border: '1px solid #000000', backgroundColor: '#e8e8e8', width: '95%' }}>
            <Typography

            > <span style={titulo}> DESCRIÇÃO:
              </span>  {chamadoDescricao}</Typography>
          </Box>

          <Box sx={{ height: '10%', width: '95%' }}>
            <Button
              size="small"
              variant="contained"
              color="primary"
              type="button"
              startIcon={<LoupeIcon />}
              onClick={handleModal}
            >
              Adicionar comentário
            </Button>

            {/* <ModalAdicaoDetalhes /> */}
          </Box>
          <Box sx={{ height: '70%', display: 'flex', gap: 1, flexDirection: 'column', overflow: 'scroll', width: '95%' }}>
            {listaChamados && listaChamados.map(item =>
              <CardDetalheSolicitanteX key={item.Detalhe}
                idDetalhe={item.idDetalhe}
                idChamado={item.idChamado}
                descricao={item.descricao}
                previsaoEntrega={item.previsaoEntrega}
                previsaoInicio={item.previsaoInicio}
                responsavelAprovacao={item.responsavelAprovacao}
                responsavelExecucao={item.responsavelExecucao}
                observacao={item.observacao}
              />
            )}
          </Box>
        </Box>


      </Modal>
    </>
  );
}