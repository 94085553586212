import { useCallback, useEffect, useState } from 'react';
import LayoutNovo from '../../components/LayoutNovo';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import TitlePage from '../../components/TitlePage';
import { Box, Button, MenuItem, Select, TextField } from '@mui/material';
import { TabelaRecebimento } from './TabelaRecebimento/TabelaRecebimento';
import { consultaContainer } from '../../services/recebimentoPermanenciaContainer.service';
import { consultaExpedicaoCarga } from '../../services/recebimentoPermanenciaContainer.service';
import { TabelaExpedicao } from './TabelaExpedicao/TabelaExpedicao';
import { apiFabrica } from '../../services/apis';
import { Modal, FormLabel } from 'react-bootstrap';
import { AtualizaImagemTransportadora } from '../../services/recebimentoPermanenciaContainer.service';
import { AdicionarExpedicao } from '../../services/recebimentoPermanenciaContainer.service';
import { consultaTransportadora } from '../../services/recebimentoPermanenciaContainer.service';
import { useToast } from '../../hooks/components/toast.hook';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import ReactFileReader from 'react-file-reader';
import { FiCamera } from 'react-icons/fi';
import './styles.css';
import { formatDateVisual } from '../../utils/formatDateInput';
import moment from 'moment/moment';

const interfaceCadastroExpedicao = {
  status: '',
  transportadora: '',
  tipO_CARGA: '',
  cubagem: 0,
  veiculo: '',
  uf: '',
  destino: '',
  doca: '',
  chegada: '',
  prioridade: '',
  motorista: '',
};

const interfaceCadastroImagem = {
  a4_COD: '',
  logO_TRANS: '',
};

const interfaceTransportadora = {
  a4_FILIAL: '',
  a4_COD: '',
  a4_NOME: '',
  a4_NREDUZ: '',
  a4_VIA: '',
  a4_END: '',
  a4_MUN: '',
  a4_BAIRRO: '',
  a4_COD_MUN: '',
  a4_EST: '',
  a4_CEP: '',
  a4_DDD: '',
  a4_TEL: '',
  a4_CGC: '',
  a4_EMAIL: '',
  deletado: '',
};

const interfaceStatusCadastro = [
  {
    id: 1,
    status: 'EM DOCA',
  },
  {
    id: 2,
    status: 'EM CARREGAMENTO',
  },
  {
    id: 3,
    status: 'EM DESCARGA',
  },
  {
    id: 4,
    status: 'ACESSANDO O PATIO',
  },
  {
    id: 5,
    status: 'LIBERADO',
  },
  {
    id: 6,
    status: 'CANCELADO',
  },
  {
    id: 7,
    status: 'AGUARD. SEPARAÇÃO',
  },
];

const interfacePrioridade = [
  {
    id: 1,
    status: 'Alta',
  },
  {
    id: 2,
    status: 'Média',
  },
  {
    id: 3,
    status: 'Baixa',
  },
];

export function PainelAeroporto() {
  const [recebimentoContainerLista, setRecebimentoContainerLista] = useState(
    []
  );
  interfaceCadastroImagem;
  const [listaExpedicao, setListaExpedicao] = useState([]);
  const { addToast } = useToast();
  const [showModal, setShowModal] = useState(false);
  const [showModalImagem, setShowModalImagem] = useState(false);
  const [cadastroImagem, setCadastroImagem] = useState(interfaceCadastroImagem);
  const [cadastroExpedicao, setCadastroExpedicao] = useState(
    interfaceCadastroExpedicao
  );
  const [statusObj, setStatusObj] = useState(interfaceStatusCadastro);
  const [prioridadeObj, setPrioridadeObj] = useState(interfacePrioridade);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingImagem, setIsLoadingImagem] = useState(false);

  const [transportadoraObj, setTransportadoraObj] = useState([
    interfaceTransportadora,
  ]);

  const [filtro, setFiltro] = useState({
    id: 0,
    produto: null,
    bl: null,
    data: moment().format('yyyy-MM-DD'),
    nf: null,
  });

  const [filtroExpedicao] = useState({
    Doca: '',
    DataChegada: moment().format('yyyy-MM-DD'),
    Status: '',
  });

  const handleCloseCadastroExpedicao = () => {
    setShowModal(!showModal);
    setCadastroExpedicao(interfaceCadastroExpedicao);
  };

  const handleCloseCadastroImagem = () => {
    setShowModalImagem(!showModalImagem);
    setCadastroImagem(interfaceCadastroImagem);
  };

  const inputTextHandler = (e) => {
    const { name, value } = e.target;

    setCadastroExpedicao({ ...cadastroExpedicao, [name]: value });
  };

  const handleFiles = (files) => {
    setCadastroImagem({
      ...cadastroImagem,
      logO_TRANS: files.base64,
    });
  };

  const AtualizarImagem = useCallback((obj) => {
    setIsLoadingImagem(true);
    const url = obj.logO_TRANS;
    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], 'ImgLogo', { type: 'image/png' });

        const formData = new FormData();
        formData.append('A4_COD', obj.a4_COD);
        formData.append('LOGO_TRANS', file);
        apiFabrica.put(`Transportadora_sa4`, formData)
          .then((r) => {
            setIsLoadingImagem(false);
            setTimeout(() => {
              handleFetch();
              }, 2000);
            handleCloseCadastroImagem();
            addToast({
              type: 'success',
              title: 'Sucesso ao Atualizar Imagem!',
            });
          })
          .catch((err) => {
            addToast({
              type: 'warning',
              title: 'Erro ao Atualizar Imagem!',
            });
          });
      });
  });

  const AdicionaExpedicaoFunc = useCallback((obj) => {
    setIsLoading(true);
    try {
      AdicionarExpedicao(obj);
    } catch (err) {
      setIsLoading(false);
      addToast({
        type: 'warning',
        title: 'Erro ao cadastrar carregamento!',
      });
    } finally {
      setTimeout(() => {
        handleFetch();
        }, 2000);
      setIsLoading(false);
      handleCloseCadastroExpedicao();
      addToast({
        type: 'success',
        title: 'Sucesso ao cadastrar carregamento!',
      });
    }
  });

  const handleFetch = useCallback(() => {
    consultaContainer(filtro)
      .then((retorno) => {
        setRecebimentoContainerLista(retorno);
      })
      .catch((_err) => {
        addToast({
          type: 'danger',
          title: 'Erro',
          description: 'Erro ao carregar as informações',
        });
      });

    consultaTransportadora()
      .then((retorno) => {
        setTransportadoraObj(retorno);
      })
      .catch((_err) => {
        addToast({
          type: 'danger',
          title: 'Erro',
          description: 'Erro ao carregar as informações',
        });
      });

    consultaExpedicaoCarga(filtroExpedicao)
      .then((retorno) => {
        setListaExpedicao(retorno);
      })
      .catch((_err) => {
        addToast({
          type: 'danger',
          title: 'Erro',
          description: 'Erro ao carregar as informações',
        });
      });
  }, [filtro]);

  const MINUTE_MS = 20000;

  useEffect(() => {
    const interval = setInterval(() => {
      handleFetch();
    }, MINUTE_MS);
  
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    handleFetch();
  }, [filtro]);

  return (
    <>
      <Modal size="lg" show={showModal} onHide={handleCloseCadastroExpedicao}>
        <Modal.Header closeButton>
          <Modal.Title>Adicionar Chegadas</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            gap: '15px',
          }}
        >
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '32%' }}
          >
            <FormLabel>Status</FormLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={cadastroExpedicao.status}
              label="Status"
              onChange={(e) =>
                setCadastroExpedicao({
                  ...cadastroExpedicao,
                  status: e.target.value,
                })
              }
            >
              {statusObj.map((item) => (
                <MenuItem key={item.id} value={item.status}>
                  {item.status}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '32%' }}
          >
            <FormLabel>Transportadora</FormLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={cadastroExpedicao.transportadora}
              label="Access"
              onChange={(e) =>
                setCadastroExpedicao({
                  ...cadastroExpedicao,
                  transportadora: e.target.value,
                })
              }
            >
              {transportadoraObj.map((item) => (
                <MenuItem key={item.a4_COD} value={item.a4_COD}>
                  {`${item.a4_NREDUZ}`}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '32%',
              alignItems: 'center',
            }}
          >
            <FormLabel>Tipo carga</FormLabel>
            <TextField
              label="Tipo Carga"
              type="text"
              id="tipO_CARGA"
              name="tipO_CARGA"
              value={cadastroExpedicao.tipO_CARGA}
              onChange={inputTextHandler}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '32%',
              alignItems: 'center',
            }}
          >
            <FormLabel>Cubagem</FormLabel>
            <TextField
              label="Cubagem"
              type="number"
              id="cubagem"
              name="cubagem"
              value={cadastroExpedicao.cubagem}
              onChange={inputTextHandler}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '32%',
              alignItems: 'center',
            }}
          >
            <FormLabel>Veículo</FormLabel>
            <TextField
              label="Veiculo"
              type="text"
              id="veiculo"
              name="veiculo"
              value={cadastroExpedicao.veiculo}
              onChange={inputTextHandler}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '32%',
              alignItems: 'center',
            }}
          >
            <FormLabel>UF</FormLabel>
            <TextField
              label="Uf"
              type="text"
              id="uf"
              name="uf"
              inputProps={{ maxLength: 2 }}
              value={cadastroExpedicao.uf}
              onChange={inputTextHandler}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '32%',
              alignItems: 'center',
            }}
          >
            <FormLabel>Destino/Cliente</FormLabel>
            <TextField
              label="Destino/Cliente"
              type="text"
              id="destino"
              name="destino"
              value={cadastroExpedicao.destino}
              onChange={inputTextHandler}
            />
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '32%',
              alignItems: 'center',
            }}
          >
            <FormLabel>Doca</FormLabel>
            <TextField
              label="Doca"
              type="text"
              id="doca"
              name="doca"
              value={cadastroExpedicao.doca}
              onChange={inputTextHandler}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '32%',
              alignItems: 'center',
            }}
          >
            <FormLabel>Motorista</FormLabel>
            <TextField
              label="Motorista"
              type="text"
              id="motorista"
              name="motorista"
              value={cadastroExpedicao.motorista}
              onChange={inputTextHandler}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '32%',
              alignItems: 'center',
            }}
          >
            <FormLabel>Chegada</FormLabel>
            <input
              step="2"
              id="chegada"
              type="datetime-local"
              name="chegada"
              value={formatDateVisual(cadastroExpedicao.chegada)}
              onChange={inputTextHandler}
            />
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '32%',
              alignItems: 'center',
            }}
          >
            <FormLabel>Prioridade</FormLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={cadastroExpedicao.prioridade}
              label="Prioridade"
              onChange={(e) =>
                setCadastroExpedicao({
                  ...cadastroExpedicao,
                  prioridade: e.target.value,
                })
              }
            >
              {prioridadeObj.map((item) => (
                <MenuItem key={item.id} value={item.status}>
                  {item.status}
                </MenuItem>
              ))}
            </Select>
          </div>
        </Modal.Body>
        <div
          style={{
            margin: 'auto',
          }}
        >
          <LoadingButton
            loading={isLoading}
            style={{
              backgroundColor: 'green',
              color: 'white',
              width: '200px',
              marginTop: '20px',
              marginBottom: '20px',
            }}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            onClick={() => {
              AdicionaExpedicaoFunc(cadastroExpedicao);
            }}
          >
            <span>Adicionar</span>
          </LoadingButton>
        </div>
      </Modal>

      <Modal
        size="lg"
        show={showModalImagem}
        onHide={handleCloseCadastroImagem}
      >
        <Modal.Header closeButton>
          <Modal.Title>Atualizar Imagem Transportadora</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            gap: '15px',
          }}
        >
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '32%' }}
          >
            <FormLabel>Transportadora</FormLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={cadastroImagem.a4_COD}
              label="Access"
              onChange={(e) =>
                setCadastroImagem({
                  ...cadastroImagem,
                  a4_COD: e.target.value,
                })
              }
            >
              {transportadoraObj.map((item) => (
                <MenuItem key={item.a4_COD} value={item.a4_COD}>
                  {`${item.a4_MUN} ${' - '} ${item.a4_NREDUZ}`}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '32%',
              alignItems: 'center',
            }}
          >
            <FormLabel>Selecionar Imagem</FormLabel>
            <div>
              <ReactFileReader
                className="botaoUpload"
                fileTypes={['.png', '.jpg']}
                base64={true}
                handleFiles={handleFiles}
              >
                <FiCamera style={{ width: 30, height: 30 }} as={Button} />
              </ReactFileReader>
            </div>
          </div>
          <>
            {cadastroImagem.logO_TRANS ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '32%',
                  alignItems: 'center',
                }}
              >
                <FormLabel>Imagem Selecionada</FormLabel>
                <img
                  width="150"
                  height="60"
                  src={`${cadastroImagem.logO_TRANS}`}
                />
              </div>
            ) : (
              <></>
            )}
          </>
        </Modal.Body>
        <div
          style={{
            margin: 'auto',
          }}
        >
          <LoadingButton
            loading={isLoadingImagem}
            style={{
              backgroundColor: 'green',
              color: 'white',
              width: '200px',
              marginTop: '20px',
              marginBottom: '20px',
            }}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            onClick={() => {
              AtualizarImagem(cadastroImagem);
            }}
          >
            <span>Atualizar</span>
          </LoadingButton>
        </div>
      </Modal>

      <LayoutNovo setorColor={'logistica'}>
        <Box position={'relative'} gap={2}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              pr: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                pr: 2,
              }}
            >
              <TitlePage
                title={'Painel Aeroporto'}
                iconChildren={
                  <FlightTakeoffIcon fontSize="large" color="#ffffff" />
                }
              />
            </Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
            }}
          >
            <Button
              style={{
                backgroundColor: '#007aff',
              }}
              className="ms-2"
              variant="contained"
              size="small"
              onClick={() => {
                setShowModalImagem(!showModalImagem);
              }}
            >
              Atualizar imagem
            </Button>
            <Button
              style={{
                backgroundColor: '#007aff',
              }}
              className="ms-2"
              variant="contained"
              size="small"
              onClick={() => {
                setShowModal(!showModal);
              }}
            >
              Cadastrar Carregamento
            </Button>
          </Box>
          <Box
            margin={1}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            gap={2}
          >
            <h4>PARTIDAS</h4>
          </Box>
          <TabelaExpedicao
            listaExpedicao={listaExpedicao}
            handleFetch={handleFetch}
          />

          <Box
            margin={1}
            display={'flex'}
            paddingTop={3}
            justifyContent={'center'}
            alignItems={'center'}
            gap={2}
          >
            <h4>CHEGADAS</h4>
          </Box>
          <TabelaRecebimento
            listaRecebimento={recebimentoContainerLista}
            handleFetch={handleFetch}
          />
          <Box
            margin={1}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            gap={2}
          ></Box>
        </Box>
      </LayoutNovo>
    </>
  );
}
