import { useState } from 'react';
import { FiInfo } from 'react-icons/fi';
import { Toast, ToastContainer } from 'react-bootstrap';

const ToastWarning = ({ message }) => {
  const [show, setShow] = useState(true);

  const [position, setPosition] = useState('top-end')

  return (
    <Toast onClose={() => setShow(false)} show={show} delay={6000} autohide bg={message.type} animation={true}>  
      <Toast.Header icon="danger">
        <FiInfo size={24} className="me-3" />
        <strong className="me-auto">{message.title}</strong>
        <small>agora</small>
      </Toast.Header>
      <Toast.Body className={message.type !== 'light' && 'text-white'}>{message.description}</Toast.Body>
      {message.descriptionEnglish && (
        <Toast.Body>{message.descriptionEnglish}</Toast.Body>
      )}
      {message.descriptionChinese && (
        <Toast.Body>{message.descriptionChinese}</Toast.Body>
      )}
    </Toast>
  );
};

export default ToastWarning;
