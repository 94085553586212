import { Box, Button, CircularProgress, Modal, Typography } from '@mui/material'
import React from 'react'
import { formatDateBrNumber } from '../../../utils/formatDateInput';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    minWidth: 500,
    maxWidth: 800,
    height: 350,
    bgcolor: 'white',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display: 'flex',
};

export default function ModalCheckQrCode({ openModal, handleCloseModal, qrCodeCheck = {
    data: "",
    produto: "",
    produtoImagem: null,
    galpao: "",
    linha: ""
}, loading }) {

    return (
        <Modal open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
                {
                    loading ? <Box sx={{ display: 'flex' }}>
                        <CircularProgress />
                    </Box> :
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Box mb={1} fullWidth>
                                    <Typography sx={{ bgcolor: '#F00', color: '#fff' }} px={1}>DATA</Typography>
                                    <Typography sx={{ bgcolor: '#fff', color: '#000', fontSize: 24 }} px={1}>{formatDateBrNumber(qrCodeCheck.data)}</Typography>
                                </Box>
                                <Box mb={1} fullWidth>
                                    <Typography sx={{ bgcolor: '#F00', color: '#fff' }} px={1}>PRODUTO</Typography>
                                    <Typography sx={{ bgcolor: '#fff', color: '#000', fontSize: 24 }} px={1}>{qrCodeCheck.produto}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                                    <Box mb={1} flex={2} fullWidth>
                                        <Typography sx={{ bgcolor: '#F00', color: '#fff' }} px={1}>GALPÃO</Typography>
                                        <Typography sx={{ bgcolor: '#fff', color: '#000', fontSize: 24 }} px={1}>{qrCodeCheck.galpao}</Typography>
                                    </Box>
                                    <Box mb={1} flex={1} fullWidth>
                                        <Typography sx={{ bgcolor: '#F00', color: '#fff' }} px={1}>LINHA</Typography>
                                        <Typography sx={{ bgcolor: '#fff', color: '#000', fontSize: 24 }} px={1}>{qrCodeCheck.linha}</Typography>
                                    </Box>
                                </Box>
                                <Button sx={{ justifySelf: 'flex-end' }} type='button' variant="contained" onClick={handleCloseModal}>
                                    FECHAR
                                </Button>
                            </Box>
                            <Box fullWidth>
                                <Box
                                    fullWidth
                                    component="img"
                                    sx={{
                                        width: '100%',
                                        objectFit: 'contain',
                                        height: '100%'
                                    }}
                                    alt="Imagem do Produto"
                                    src={`data:image/png;base64,${qrCodeCheck.produtoImagem}`}
                                />
                            </Box>
                        </Box>
                }
            </Box>
        </Modal>
    )
}
