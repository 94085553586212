import React, { useMemo } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import ModalFechar from '../ModalCadastrarFim';
import { Box } from '@mui/material';

const TableApontamentos = ({ nome, data, handleFetchApontamentos }) => {


  const columns = useMemo(
    () => [
      {
        accessorKey: 'colaboradorNome',
        header: 'Colaborador',
        size: 150,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'dataApont',
        header: 'Data',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'hr_Inicio',
        header: 'Hora Inicio',
        size: 150,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'hr_Final',
        header: 'Hora Fim',
        size: 150,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'numChamado',
        header: 'Numero do Chamado',
        size: 150,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'tarefa_Demanda',
        header: 'Tarefa',
        size: 150,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'tempo_Min',
        header: 'Tempo em Minutos',
        size: 150,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'observacao',
        header: 'Observação',
        size: 150,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'id',
        header: 'Registrar Fim',
        size: 50,
        Cell: ({ cell }) => {
          return (
            <Box>
              <ModalFechar
                data={cell.row.original}
                nome={nome}
                handleFetchApontamentos={handleFetchApontamentos}
                />
            </Box>
          );
        },
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
    ],
    []
  );

  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      localization={MRT_Localization_PT_BR}
      muiTopToolbarProps={{
        sx: { bgcolor: '#D7D7D7', color: 'white' },
      }}
    />
  );
};

export default TableApontamentos;
