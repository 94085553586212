import React from 'react';

import { Table } from 'react-bootstrap';
import { useEntregas } from '../../../hooks/entrega/entregas.hook';
import { BiError } from 'react-icons/bi';
import { BsCheckCircleFill } from 'react-icons/bs';

const NotasConfirmacao = () => {
  const { listaConfirmadas } = useEntregas();

  return (
    <Table className="mt-3">
      <thead>
        <tr>
          <th>Nota Fiscal</th>
          <th>Nome</th>
          <th>Destino</th>
          <th style={{ textAlign: 'center' }}>Expedido</th>
        </tr>
      </thead>
      <tbody>
        {listaConfirmadas?.map((item, index) => (
          <tr key={index}>
            <td style={{ fontSize: 14 }}>{item.documento}</td>
            <td style={{ fontSize: 14 }}>{item.nome}</td>
            <td style={{ fontSize: 14 }}>{item.destino}</td>
            <td style={{ fontSize: 14, textAlign: 'center' }}>
              {item.expedido === 0 ? (
                <BiError size={20} style={{ color: 'orange' }} />
              ) : (
                <BsCheckCircleFill size={24} style={{ color: 'green' }} />
              )}
            </td>
          </tr>
        )).reverse()}
      </tbody>
    </Table>
  );
};

export default NotasConfirmacao;
