import { Box, Button, Typography } from '@mui/material'
import React from 'react'

import { formatDateTime } from '../../../utils/formatDateInput';


export default function CardDetalheSolicitanteX({ idDetalhe = '', idChamado = '', descricao = '', previsaoEntrega = '', previsaoInicio = '', responsavelAprovacao = '', responsavelExecucao = '', observacao = '', handleGetChamadoDet, handleDeleteChamadoDet }) {
  return (
    <Box sx={{ border: '1px solid #000', p: 3, wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}>
        <Box sx={{ display:'flex', gap: 3 }}>
        <Typography sx={{ color:"#000" }}>ID Detalhe: {idDetalhe}</Typography>
        <Typography sx={{ color:"#000" }}>ID Chamado: {idChamado}</Typography>
        </Box>
        <hr></hr>
        <Box sx={{ display:'flex', gap: 3 }}>
        <Typography sx={{ color:"#000" }}>Previsão de Entrega: {formatDateTime(previsaoEntrega)}</Typography>
        <Typography sx={{ color:"#000" }}>Previsão de Início: {formatDateTime(previsaoInicio)}</Typography>
        </Box>
        <Typography sx={{ color:"#000" }}>Responsável por Aprovação: {responsavelAprovacao}</Typography>
        <Typography sx={{ color:"#000" }}>Responsável por Execução: {responsavelExecucao}</Typography>
        <Typography sx={{ color:"#000" }}>Descrição: {descricao}</Typography>
        <Typography sx={{ color:"#000", wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}>Observação: {observacao}</Typography>
    </Box>
  )
}