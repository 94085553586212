import React, { useState, useEffect, useCallback } from 'react';
import debounce from '../../utils/debounce';
import { apiFabrica_operacao } from '../../services/apis.js';
import './styles.css';
import { formatDatetoHtmlDay } from '../../utils/formatDate';
import ClearIcon from '@mui/icons-material/Clear';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  TextField,
  InputLabel,
  OutlinedInput,
  Typography,
  Stack,
  Pagination,
  Select,
  MenuItem,
} from '@mui/material';
import TabelaApontamentoSanduicheira from './tabela/tabelaApontamentoSanduicheira.js';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
dayjs.locale('pt-br');
import { IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import FormLabel from '@mui/material/FormLabel';
import Amvoxlogopng from '../../assets/Amvoxlogopng.png';
import { useNavigate } from 'react-router';
import './styles.css';
import LayoutNovo from '../../components/LayoutNovo';
import { buscarApontamentosSanduicheirasPorFiltro } from '../../services/apontamentoSanduicheiraService.js';

const interfaceDadosGeral = {
  apontamentosSanduicheira: [
    {
      id: 0,
      ean: '',
      dataRegistro: '',
      linhaID: 0,
      galpaoID: 0,
      op: '',
      codigoProduto: '',
      situacaoSanduich: 0,
    },
  ],
  totalPaginas: 0,
  totalApontamentos: 0,
};

// const ListaGalpao = [
//   { nome: 'CORDEBRAS', id: 0 },
//   { nome: 'SEDE', id: 1 },
// ];

export default function ApontamentoSanduicheira() {
  const [apontamentoSanduicheiraLista, setApontamentoSanduicheiraLista] =
    useState(interfaceDadosGeral);
  const [numeroSanduicheiras, setNumeroSanduicheiras] = useState([]);
  const [loading, setLoading] = useState(true);
  const [eanInput, setEanInput] = useState('');
  const [produtoInput, setProdutoInput] = useState('');
  const [opInput, setOpInput] = useState('');


  // const [listaGalpoes, setListaGalpoes] = useState([]);
  // const [listaLinhas, setListaLinhas] = useState([]);

  // const fetchLinhas = async () => {
  //   try {
  //     const response = await apiFabrica_operacao.get(
  //       'Producao/EsteirasProducao'
  //     );
  //     setListaLinhas(response.data.value.producao_esteira);
  //   } catch (err) {
  //     console.error('Erro ao buscar Linhas:', err);
  //   }
  // };

  // const fetchGalpoes = async () => {
  //   try {
  //     const response = await apiFabrica_operacao.get('Galpao');
  //     setListaGalpoes(response.data);
  //   } catch (err) {
  //     console.error('Erro ao buscar galpões:', err);
  //   }
  // };

  const navigate = useNavigate();

  const [filtro, setFiltro] = useState({
    ean: null,
    dataInicio: formatDatetoHtmlDay(),
    dataFim: formatDatetoHtmlDay(),
    op: null,
    codigoProduto: null,
    nomeProduto: null,
    idLinha: null,
    idGalpao: null,
    pagina: 1,
    qtdPorPagina: 20,
  });

  const [filtroTotal, setFiltroTotal] = useState({
    dataInicio: formatDatetoHtmlDay(),
    dataFim: formatDatetoHtmlDay(),
  });

  function handleBack() {
    navigate(-1);
  }

  const formatDateForDisplay = (date) => {
    return dayjs(date).format('DD/MM/YYYY');
  };

  const handleFetch = useCallback(() => {
    buscarApontamentosSanduicheirasPorFiltro(filtro)
      .then((retorno) => {
        setApontamentoSanduicheiraLista(retorno);
      })
      .catch((_err) => {
        addToast({
          type: 'danger',
          title: 'Erro na tabela de apontamento sanduicheira !',
          description:
            'Erro ao carregar tabela de apontamentos sanduicheira, por favor tente novamente dentre de instantes !',
        });
      })
      .finally(() => setLoading(false));
  }, [filtro]);

  const handleFetchCard = async () => {
    try {
      const response = await apiFabrica_operacao.get(
        `ApontamentosSanduicheira/Cards`,
        { params: filtroTotal }
      );
      setNumeroSanduicheiras(response.data);
    } catch (err) {
      addToast({
        type: 'danger',
        title: 'Erro Tabela Sanduicheira !',
        description: 'Erro grave ',
      });
    }
  };

  useEffect(() => {
    handleFetch();
  }, [filtro]);

  // useEffect(() => {
  //   fetchGalpoes();
  //   fetchLinhas();
  // }, []);

  useEffect(() => {
    handleFetchCard();
  }, [filtroTotal]);

  const handleClear = (e) => {
    e.preventDefault();
    setEanInput('');
    setOpInput('');
    setProdutoInput('');
    setFiltro({
      ean: '',
      dataInicio: formatDatetoHtmlDay(),
      dataFim: formatDatetoHtmlDay(),
      op: null,
      codigoProduto: null,
      nomeProduto: null,
      idLinha: null,
      idGalpao: null,
      pagina: 1,
      qtdPorPagina: 20,
    });
  };

  const handlePageChange = (event, value) => {
    setFiltro((prevFiltro) => ({
      ...prevFiltro,
      pagina: value,
    }));
  };

  // const handleSelectChange = (event) => {
  //   const novoValor = event.target.value;
  //   setFiltro((prevData) => ({
  //     ...prevData,
  //     idGalpao: novoValor,
  //   }));
  // };

  return (
    <>
      <LayoutNovo>
        <div className="principal">
          <Box
            position={'relative'}
            sx={{ backgroundColor: '#F2F2F2' }}
            gap={2}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                pl: 4,
              }}
            >
              <IconButton onClick={handleBack}>
                <ChevronLeftIcon />
              </IconButton>
              <img src={Amvoxlogopng} alt="Amvox" className="imgAmvox"></img>
              <div className="tituloPri">Apontamento Sanduicheira</div>
              <Box
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                }}
              />
            </Box>
            <div className="listaCardApontamentoSanduicheira">
              <div className="cardApontamentoSanduicheira">
                <Typography
                  variant="h7"
                  style={{
                    fontWeight: 'bold',
                    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
                    textAlign: 'left',
                  }}
                >
                  {' '}
                  Produção Total Periodo
                </Typography>

                <FormLabel
                  style={{
                    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
                    fontSize: '24px',
                    textAlign: 'left',
                    color: 'green',
                  }}
                >
                  {numeroSanduicheiras.producaoTotalPeriodo}
                </FormLabel>
              </div>

              <div className="cardApontamentoSanduicheira">
                <Typography
                  variant="h7"
                  style={{
                    fontWeight: 'bold',
                    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  {' '}
                  Produção Total Ano
                </Typography>

                <FormLabel
                  style={{
                    textAlign: 'center',
                    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
                    fontSize: '24px',
                    textAlign: 'left',
                    color: 'green',
                  }}
                >
                  {numeroSanduicheiras.producaoTotalAno}
                </FormLabel>
              </div>
              <div className="cardApontamentoSanduicheira">
                <Typography
                  variant="h7"
                  style={{
                    fontWeight: 'bold',
                    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  {' '}
                  Qtd Produzido com Defeito
                </Typography>

                <FormLabel
                  style={{
                    textAlign: 'center',
                    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
                    fontSize: '24px',
                    textAlign: 'left',
                    color: 'red',
                  }}
                >
                  {numeroSanduicheiras.qtdProduzidaComDefeito}
                </FormLabel>
              </div>
            </div>
            <div className="divGeralApontamentoSanduicheira">
              <div className="divFiltroApontamentoSanduicheira">
                <div className="divSelects">
                  <div
                    style={{
                      flexDirection: 'column',
                      paddingLeft: '10px',
                      maxWidth: '160px',
                      fontWeight: 'bold',
                      textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
                      width: '100%',
                    }}
                  >
                    <InputLabel style={{ fontWeight: 'bold' }}>
                      Data Inicial
                    </InputLabel>
                    <Grid item xs={5} sm={1.6} maxWidth={'150px'}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={
                            filtro.dataInicio
                              ? dayjs(filtro.dataInicio, 'YYYY-MM-DD').toDate()
                              : null
                          }
                          onChange={(date) =>
                            debounce(() => {
                              const formattedDate = date
                                ? dayjs(date).format('YYYY-MM-DD')
                                : null;
                              setFiltro({
                                ...filtro,
                                dataInicio: formattedDate,
                              });
                              setFiltroTotal((prevFiltroTotal) => ({
                                ...prevFiltroTotal,
                                dataInicio: formattedDate,
                              }));
                              setLoading(true);
                            })
                          }
                          renderInput={(params) => (
                            <TextField
                              id="DataInicial"
                              {...params}
                              size="small"
                              fullWidth
                              sx={{ bgcolor: 'transparent', borderRadius: 2 }}
                              value={formatDateForDisplay(filtro.dataInicio)}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </div>
                  <div
                    style={{
                      flexDirection: 'column',
                      fontWeight: 'bold',
                      maxWidth: '160px',
                      textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
                      width: '100%',
                    }}
                  >
                    <InputLabel style={{ fontWeight: 'bold' }}>
                      Data Final
                    </InputLabel>
                    <Grid item xs={5} sm={1.6} maxWidth={'150px'}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={
                            filtro.dataFim
                              ? dayjs(filtro.dataFim, 'YYYY-MM-DD').toDate()
                              : null
                          }
                          onChange={(date) =>
                            debounce(() => {
                              const formattedDate = date
                                ? dayjs(date).format('YYYY-MM-DD')
                                : null;
                              setFiltro({
                                ...filtro,
                                dataFim: formattedDate,
                              });
                              setFiltroTotal((prevFiltroTotal) => ({
                                ...prevFiltroTotal,
                                dataFim: formattedDate,
                              }));
                              setLoading(true);
                            })
                          }
                          renderInput={(params) => (
                            <TextField
                              id="DataFinal"
                              {...params}
                              size="small"
                              fullWidth
                              sx={{ bgcolor: 'transparent', borderRadius: 2 }}
                              value={formatDateForDisplay(filtro.dataFim)}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingLeft: '10px',
                      fontWeight: 'bold',
                      textShadow: '1px 1px 1px rgba(0, 0, 0, 0.1)',
                      width: '100%',
                    }}
                  >
                    <InputLabel style={{ fontWeight: 'bold' }}>
                      Produto
                    </InputLabel>
                    <OutlinedInput
                      id="nomeProduto"
                      placeholder="Produto"
                      value={produtoInput}
                      onChange={(e) => {
                        const value = e.target.value;
                        setProdutoInput(value);
                        debounce(() => {
                          setFiltro((prevFiltro) => ({
                            ...prevFiltro,
                            nomeProduto: value,
                            dataInicio: null,
                            dataFim: null,
                          }));
                        });
                      }}
                      sx={{
                        bgcolor: '#fff',
                        borderRadius: 2,
                        '& .MuiOutlinedInput-input': {
                          padding: '10px 14px',
                        },
                        '& .MuiOutlinedInput-root': {
                          height: 40,
                        },
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingLeft: '10px',
                      fontWeight: 'bold',
                      textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
                      width: '100%',
                    }}
                  >
                    <InputLabel style={{ fontWeight: 'bold' }}>EAN</InputLabel>
                    <OutlinedInput
                      id="ean"
                      placeholder="EAN"
                      value={eanInput}
                      onChange={(e) => {
                        const value = e.target.value;
                        setEanInput(value);
                        debounce(() => {
                          setFiltro((prevFiltro) => ({
                            ...prevFiltro,
                            ean: value,
                            dataInicio: null,
                            dataFim: null,
                          }));
                        });
                      }}
                      sx={{
                        bgcolor: '#fff',
                        borderRadius: 2,
                        '& .MuiOutlinedInput-input': {
                          padding: '10px 14px',
                        },
                        '& .MuiOutlinedInput-root': {
                          height: 40,
                        },
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingLeft: '10px',
                      fontWeight: 'bold',
                      textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
                      width: '450px',
                    }}
                  >
                    <InputLabel style={{ fontWeight: 'bold' }}>OP</InputLabel>
                    <OutlinedInput
                      id="op"
                      placeholder="OP"
                      value={opInput}
                      onChange={(e) => {
                        const value = e.target.value;
                        setOpInput(value);
                        debounce(() => {
                          setFiltro((prevFiltro) => ({
                            ...prevFiltro,
                            op: value,
                            dataInicio: null,
                            dataFim: null,
                          }));
                        });
                      }}
                      
                      sx={{
                        bgcolor: '#fff',
                        borderRadius: 2,
                        '& .MuiOutlinedInput-input': {
                          padding: '10px 14px',
                        },
                        '& .MuiOutlinedInput-root': {
                          height: 40,
                        },
                      }}
                    />
                  </div>
                  {/* <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingLeft: '10px',
                      fontWeight: 'bold',
                      textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
                      width: '90px',
                    }}
                  >
                     <InputLabel id="linha-label" style={{ fontWeight: 'bold' }}>
                      Linha
                    </InputLabel>
                    <Select
                      labelId="linha-label"
                      id="linha"
                      value={filtro.idLinha}
                      onChange={(e) =>
                        setFiltro({
                          ...filtro,
                          idLinha: e.target.value,
                          dataInicio: null,
                          dataFim: null,
                        })
                      }
                      input={<OutlinedInput />}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          height: 40,
                        },
                      }}
                    >
                      {listaLinhas.map((linha) => (
                        <MenuItem key={linha.esteiraID} value={linha.esteiraID}>
                          {linha.nome_Esteira}
                        </MenuItem>
                      ))}
                    </Select>
                  </div> */}

                  {/* <div style={{ paddingLeft: '10px', width: '100%' }}>
                    <InputLabel style={{ fontWeight: 'bold' }}>
                      Galpão
                    </InputLabel>
                    <Select
                      value={filtro.idGalpao ?? ''} // Garante que o valor inicial seja compatível com o Select
                      onChange={handleSelectChange}
                      displayEmpty
                      sx={{
                        bgcolor: '#fff',
                        borderRadius: 2,
                        '& .MuiSelect-select': {
                          padding: '10px 14px',
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        Selecione um galpão
                      </MenuItem>
                      {listaGalpoes.map((galpao) => (
                        <MenuItem key={galpao.id} value={galpao.id}>
                          {galpao.nome}
                        </MenuItem>
                      ))}
                    </Select>
                  </div> */}

                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingRight: '25px',
                    }}
                  >
                    <Button
                      size="small"
                      variant="outlined"
                      startIcon={<ClearIcon />}
                      sx={{
                        borderRadius: '8px',
                        border: '1px solid rgba(204, 204, 204, 0.80)',
                        backgroundColor: '#FFF',
                        color: 'black',
                        marginTop: '25px',
                        whiteSpace: 'nowrap',
                        '&:hover': {
                          backgroundColor: '#F0F0F0',
                        },
                      }}
                      onClick={handleClear}
                    >
                      Limpar Filtro
                    </Button>
                  </Box>
                </div>
              </div>
            </div>

            <TabelaApontamentoSanduicheira
              data={apontamentoSanduicheiraLista?.apontamentosSanduicheira}
            />
            <Stack
              spacing={2}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: '25px',
              }}
            >
              <Pagination
                count={apontamentoSanduicheiraLista.totalPaginas}
                page={filtro.pagina}
                onChange={handlePageChange}
                showFirstButton
                showLastButton
                color="error"
              />
            </Stack>
          </Box>
        </div>
      </LayoutNovo>
    </>
  );
}
