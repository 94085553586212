import { Container } from './styles';
import ToastWarning from './Toast';

const ToastContainer = ({ messages }) => {
  return (
    <Container>
      {messages.map((item, index) => (
        <ToastWarning key={index} message={item} />
      ))}
    </Container>
  );
};

export default ToastContainer;
