import React from 'react';

export const Relatorios = () => {
  return (
    <div className="home">
      <h1>Relatorios</h1>
    </div>
  );
};

export const RelatoriosUm = () => {
  return (
    <div className="relatorios">
      <h1>Relatorios/relatorios1</h1>
    </div>
  );
};

export const RelatoriosDois = () => {
  return (
    <div className="relatorios">
      <h1>Relatorios/relatorios2</h1>
    </div>
  );
};

export const RelatoriosTres = () => {
  return (
    <div className="relatorios">
      <h1>Relatorios/relatorios3</h1>
    </div>
  );
};
