import React, { useMemo } from 'react';
import CountUp from 'react-countup';

import dolarImg from '../../assets/dolar.svg';
import arrowUpImg from '../../assets/arrow-up.svg';
import arrowDownImg from '../../assets/arrow-down.svg';
import estoqueImg from '../../assets/Estoqueind2.png';
import industriaImg from '../../assets/industria.png';
import produto from '../../assets/produto.svg';
import truckImg from '../../assets/truck.svg';
import saldoVencimentoImg from '../../assets/vencimentoSaldo.svg';
import notasEmitidas from '../../assets/notasEmitidas.svg';
import pedidosImg from '../../assets/pedidos.svg';
import financeiroImg from '../../assets/financeiro.svg';
import containerImg from '../../assets/Container.svg';
import shipImg from '../../assets/shipImg.svg';
import faturamentoImg from '../../assets/faturamento.svg';
import ordemservicoImg from '../../assets/ordemservicoicon.svg';
import { BiCaretDown, BiCaretUp } from 'react-icons/bi';
import usuarios from '../../assets/users.svg'
import { CaretVariant, Container, Content } from './styles';
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import engineeringIcon from '../../assets/mailane.png';

const NumberBox = ({
  title,
  amount,
  footerlabel,
  value,
  icon,

  loader,
  backcolor,
  heightExtra,
  toLink,
}) => {
  const iconSelected = useMemo(() => {
    switch (icon) {
      case 'dolar':
        return dolarImg;
      case 'estoque':
        return estoqueImg;
      case 'industria':
        return industriaImg;
      case 'arrowUp':
        return arrowUpImg;
      case 'arrowDown':
        return arrowDownImg;
      case 'produto':
        return produto;
      case 'entregas':
        return truckImg;
      case 'titulosSomados':
        return saldoVencimentoImg;
      case 'notasEmitidas':
        return notasEmitidas;
      case 'pedidos':
        return pedidosImg;
      case 'financeiro':
        return financeiroImg;
      case 'container':
        return containerImg;
      case 'ship':
        return shipImg;
      case 'faturamento':
        return faturamentoImg;
      case 'usuarios':
        return usuarios;
      case 'ordemservico':
        return ordemservicoImg;
      case 'producao':
        return engineeringIcon;
      default:
        return undefined;
    }
  }, [icon]);
  return (
    <>
      {toLink ? (
        <OverlayTrigger
          placement={'top'}
          overlay={
            <Tooltip>
              <strong>{toLink}</strong>
            </Tooltip>
          }
        >
          <Container
            backcolor={backcolor}
            heightExtra={heightExtra}
            toLink={toLink}
          >
            <span>{title}</span> <br />
            {(loader ? (
              <Spinner color="light" />
            ) : (
              <>
                <Content>
                  <h1>
                    <strong>QTD </strong>
                    {amount && (
                      <CountUp
                        end={amount}
                        separator="."
                        decimal=","
                        decimals={0}
                      />
                    )}
                    {value && value}
                  </h1>
                </Content>
                <img src={iconSelected} alt={title} />
              </>
            ))}
          </Container>
        </OverlayTrigger>
      ) : (
        <Container
          backcolor={backcolor}
          heightExtra={heightExtra}
          toLink={toLink}
        >
          <span>{title}</span>
          <h1>
            <strong>QTD </strong>
            <CountUp end={amount} separator="." decimal="," decimals={0} />
          </h1>
          <img src={iconSelected} alt={title} />
        </Container>
      )}
    </>
  );
};

export default NumberBox;
