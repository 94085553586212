import { useCallback, useEffect, useState } from 'react';
import { buscarUsuarioPorFiltro } from '../services/usuarios.service';

export default function useUsuarioLocal() {
  const usuario = localStorage.getItem('@fabrica-user');
  const { email, setor, nivel, nome, id } = JSON.parse(usuario);
  
  return { 
    id,
    nome,
    setor,
    nivel,
    email,
  };
}