import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

import { logsRequest } from '../../../services/logMonitoramento.service';
import { Box } from '@mui/material';

const useStyles = makeStyles({
  table: {
    minWidth: 660,
    fontFamily: 'Poppins',
    fontSize: 12,
    fontStyle: 'normalize',
    fontWeight: 400,
    lineHeight: 'normal',
  },
});

const InterfaceProps = {
  logType: 0,
  idColaborador: 0,
  dataInicial: '2024-09-20T18:23:12.930Z',
  dataFinal: '2024-09-20T18:23:12.930Z',
};

export default function LogsTable() {
  const classes = useStyles();

  const [data, setData] = useState([InterfaceProps]);

  const handleFetchLogs = () => {
    logsRequest({ data })
      .then((response) => {
        setData(response);
      })
      .catch((_err) => {
        console.log(_err);
      });
  };

  useEffect(() => {
    handleFetchLogs();
  }, []);

  return (
    <TableContainer>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell align="left">Mensagem</TableCell>
            <TableCell align="center">Nivel</TableCell>
            <TableCell align="center">UserID</TableCell>
            <TableCell align="center">Método</TableCell>
            <TableCell align="center">Endpoint requisitado</TableCell>
            <TableCell align="center">Data da requisição</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="data">
                {row.id}
              </TableCell>
              <TableCell align="left">{row.message}</TableCell>
              <TableCell align="center">
                <Box
                  sx={{
                    display: 'flex',
                    padding: '2px 8px',
                    borderRadius: '100px',
                    alignItems: 'flex-start',
                    bgcolor: '#E8F1FF',
                  }}
                >
                  <ErrorOutlineOutlinedIcon
                    sx={{ width: '20px', height: '20px' }}
                  />
                  {row.level}
                </Box>
              </TableCell>
              <TableCell align="center">{row.userId}</TableCell>
              <TableCell align="center">{row.method}</TableCell>
              <TableCell align="center">{row.endpointRequest}</TableCell>
              <TableCell align="center">{row.requestDate}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
