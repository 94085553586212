import React, { useMemo } from 'react';
import CountUp from 'react-countup';
import ToggleOn from '../../assets/toggle-on.svg';
import ToggleOff from '../../assets/toggle-off.svg';
import Dots from '../../assets/dots.svg';

import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import dolarImg from '../../assets/dolar.svg';
import arrowUpImg from '../../assets/arrow-up.svg';
import arrowDownImg from '../../assets/arrow-down.svg';
import estoqueImg from '../../assets/Estoqueind2.png';
import industriaImg from '../../assets/industria.png';
import produto from '../../assets/produto.svg';
import truckImg from '../../assets/truck.svg';
import saldoVencimentoImg from '../../assets/vencimentoSaldo.svg';
import notasEmitidas from '../../assets/notasEmitidas.svg';
import pedidosImg from '../../assets/pedidos.svg';
import financeiroImg from '../../assets/financeiro.svg';
import containerImg from '../../assets/Container.svg';
import shipImg from '../../assets/shipImg.svg';
import notAccess from '../../assets/notAccess.svg';
import faturamentoImg from '../../assets/faturamento.svg';
import { BiCaretDown, BiCaretUp, BiMinus } from 'react-icons/bi';
import { AiOutlineUpCircle, AiOutlineDownCircle } from 'react-icons/ai';
import {
  BoxVariantCurrent,
  Container,
  ContainerDesativado,
  ContentInfo,
  ContentSetor,
  FooterCard,
  Graphic,
} from './styles';
import { TbTrendingDown, TbTrendingUp } from 'react-icons/tb';
import GraficoLinha from './grafico';

const NumberBox3 = ({
  title,
  monthValueOrDefault = null,
  loader,
  setor,
  iconSetor,
  status,
  color,
  toLink,
  valueString,
  valueVariantCurrent,
  noVariantCurrent,
  cardGraphic,
  filterBlur,
  decimals = 0,
  noLink,
  yearValue
}) => {
  const iconSelected = useMemo(() => {
    switch (iconSetor) {
      case 'dolar':
        return dolarImg;
      case 'estoque':
        return estoqueImg;
      case 'industria':
        return industriaImg;
      case 'arrowUp':
        return arrowUpImg;
      case 'arrowDown':
        return arrowDownImg;
      case 'produto':
        return produto;
      case 'entregas':
        return truckImg;
      case 'titulosSomados':
        return saldoVencimentoImg;
      case 'notasEmitidas':
        return notasEmitidas;
      case 'pedidos':
        return pedidosImg;
      case 'financeiro':
        return financeiroImg;
      case 'container':
        return containerImg;
      case 'ship':
        return shipImg;
      case 'faturamento':
        return faturamentoImg;
      default:
        return undefined;
    }
  }, [iconSetor]);

  return (
    <>
      {
        !filterBlur ? (
          <Container
            cardGraphic={cardGraphic}
            color={color}
            filterBlur={filterBlur}
          >
            {/* <img src={iconSelected} alt="" /> */}
            <p className="title">
              <img
                src={status === 1 ? ToggleOn : ToggleOff}
                style={{ width: '7%' }}
              />
              {title}
            </p>
            {status === 1 ? (
              loader ? (
                <Spinner className="spinner" />
              ) : (
                // <p className="loader">Carregando...</p>
                <>
                  {' '}
                  <div className="content">
                    {valueString && <p className="value">{valueString}</p>}
                    {!valueString && <p className="value">
                      {monthValueOrDefault && monthValueOrDefault === 0 ? (
                        <span>
                          0 <br />
                        </span>
                      ) : (
                        <>
                          <CountUp
                            end={monthValueOrDefault}
                            separator="."
                            decimal=","
                            decimals={decimals}
                          />{' '}
                          {yearValue && <>
                            | <CountUp
                              end={yearValue}
                              separator="."
                              decimal=","
                              decimals={decimals}
                            />
                          </>
                          }
                        </>
                      )}
                    </p>}

                    {
                      !noVariantCurrent && <BoxVariantCurrent cardGraphic={cardGraphic}>
                      <p className="variant_positive">
                        <AiOutlineUpCircle size={20} color="#00ff00" />{' '}
                        <span>00,00%</span>
                      </p>
                      <p className="variant_negative">
                        <AiOutlineDownCircle size={20} color="#ff0000" />{' '}
                        <span>00,00%</span>
                      </p>
                    </BoxVariantCurrent>
                    }
                    
                  </div>
                  {cardGraphic && (
                    <Graphic>
                      <GraficoLinha />
                    </Graphic>
                  )}
                </>
              )
            ) : (
              <p className="loading">
                Em Manutenção <img src={Dots} />
              </p>
            )}

            {
              !noLink && <div className="describe_link">
              <p>Ir para {toLink}</p>
            </div>
            }
            

            <FooterCard cardGraphic={cardGraphic} color={color}>
              {setor}
            </FooterCard>
          </Container>
        ) : (
          <ContainerDesativado>
            <img src={notAccess} alt="" />
            <p className='description'>Vazio</p>
          </ContainerDesativado>
        )
      }

    </>
  );
};

export default NumberBox3;
