import React, { useEffect, useState } from 'react';
import ButtonCloseModal from '../../../components/ButtonCloseModal';
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Modal,
    TextField,
    Typography,
} from '@mui/material';

import { Add, ExitToApp } from '@mui/icons-material';
import { useToast } from '../../../hooks/components/toast.hook';
import { ChamadosFecharPut } from '../../../services/fecharChamado';
import { useFetchChamados } from '../useFetchChamados';

const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    height: 600,
    bgcolor: '#fff',
    p: 4,
};

const initialState = {
    id: '',

};

export function ModalFecharChamado({ id, disabled, handleCloseChamado }) {
    const [formData, setFormData] = useState(initialState);
    const { addToast } = useToast()
    const [open, setOpen] = useState(false);
    const { handleFetch } = useFetchChamados();
    
    const handleOpen = (value) => {
        setOpen(true);
        setFormData({
            id: value,
        })
    };

    const handleClose = () => {
        setFormData(initialState);
        setOpen(false);
    };

    const handleFecharChamado2 = (value) => {
        handleCloseChamado(value);
        handleClose();
    };

    return (

        <div>
            <Button onClick={() => handleOpen(id)} variant="contained" size='small' disabled={disabled} sx={{marginTop: 1, width: '0px', height: '35px',fontSize: '11px', borderRadius: '5px'}} >
                Fechar Chamado
            </Button>
            <Modal
                open={open}
                handleClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box component={'form'} sx={{ ...style, width: 700, height: 225 }}>
                    <ButtonCloseModal onClick={handleClose} />
                    <Typography
                        variant="h6"
                        component={'p'}
                        textAlign={'center'}
                        color={'#000'}
                    >
                        Tem certeza que deseja fechar este Chamado?
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, }}>
                        <FormControl
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'start',
                                flex: 8,
                            }}
                        >
                            <FormLabel> Número do Chamado</FormLabel>
                            <TextField
                                variant="filled"
                                size="small"
                                fullWidth
                                value={formData.id}
                                sx={{ bgcolor: '#fff', borderRadius: 2 }}
                                disabled
                            />

                        </FormControl>

                        <Box sx={{ display: 'flex', justifyContent: 'end', my: 2, gap: 2 }}>
                            <Button
                                type="reset"
                                variant="contained"
                                color="primary"
                                startIcon={<Add />}
                                size="small"
                                onClick={() => handleFecharChamado2(id)}
                            >
                                Fechar Chamado
                            </Button>
                            <Button
                                variant="contained"
                                color="warning"
                                size="small"
                                endIcon={<ExitToApp />}
                                onClick={handleClose}
                            >
                                Cancelar
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}

