function TabelaDashChamadosCategoria({ data }) {
  const dadosTransformados = data.map((item) => (item.mediaEmDIas || 0) * 100);

  const dados = [
    {
      cor: '#4BACC6',
      valores: Array(data.length).fill((0.9 * 100).toFixed(2)),
    },
    {
      cor: '#2C4D75',
      valores: dadosTransformados,
    },
  ];

  return (
    <div style={{ overflowX: 'auto' }}>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <tbody>
          {dados.map((linha, rowIndex) => (
            <tr key={rowIndex}>
              <td
                style={{
                  borderRight: '1px solid rgba(221, 226, 228, 0.40)',
                  textAlign: 'center',
                  padding: '8px',
                }}
              >
                <div
                  style={{
                    backgroundColor: linha.cor,
                    height: 20,
                    width: 20,
                    borderRadius: '50%',
                    margin: 'auto',
                  }}
                />
              </td>
              {linha.valores.map((valor, colIndex) => (
                <td
                  key={colIndex}
                  style={{
                    textAlign: 'center',
                    padding: '8px',
                    borderBottom: '1px solid rgba(221, 226, 228, 0.40)',
                  }}
                >
                  {Number(valor).toFixed(2)}%
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TabelaDashChamadosCategoria;
