import styled from 'styled-components';

export const ContainerContact = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  
  img {
    width: 100%;
  }

  .imgBox {
    width: 45%;
  }
`;

export const Form = styled.form`
  background-color: var(--secondary);
  color: #fff;
  padding: 2rem;
  width: 40vw;
  max-width: 600px;
  /* border-radius: 8px; */

  label {
    color: #933333;
  }

  /* input {
    width: 99%;
    border-radius: 1rem;
    padding: 0.5rem;
    border: 1px solid gray; 
  } */

  textarea {
    padding: 0.3rem;
    width: 99%;
    resize: none;
    height: 6rem;
  }

  input[type='submit'] {
    width: 100%;
    cursor: pointer;
  }
  
  input[type='file'] {
    color: #933333;
  }

  @media (max-width: 700px) {
    width: 90%;
  }
`;
