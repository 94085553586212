import { apiFabrica_operacao } from '../apis';

export const RegistrarApontamentoFinal = async (value) => {
  try {
    const result = await apiFabrica_operacao.post(
      'PlanejamentoSemanalProducao',
      value
    );
    return Promise.resolve(result.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const atualizarPlanejamentoProducao = async (value) => {
  try {
    const result = await apiFabrica_operacao.patch(
      'PlanejamentoSemanalProducaoDetalhe',
      value,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    return Promise.resolve(result.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const ConsultarPlanejamento = async (value) => {
  try {
    const result = await apiFabrica_operacao.get(
      'PlanejamentoSemanalProducao',
      value
    );
    return Promise.resolve(result.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const ConsultaProdutos = async (value) => {
  try {
    const result = await apiFabrica_operacao.get(
      'Produtos/ProdutoSemImagem',
      value
    );
    return Promise.resolve(result.data);
  } catch (error) {
    return Promise.reject(error);
  }
};
