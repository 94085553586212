import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Box,
  Collapse,
  IconButton,
  TableHead as TableHeadMui,
  TableContainer as TableContainerMui,
  Paper,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const Row = ({ row, GetDados, setDadosFiltrados }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleRowClick = () => {
    setOpen(!open);
    if (!open && row.listaInsumosUtilizados) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  return (
    <>
      <TableRow sx={{ color: '#333333' }}>
        <TableCell align="center">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleRowClick}
          >
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.codigo}</TableCell>
        <TableCell>{row.descricao}</TableCell>
        <TableCell align="center">{row.qtdProdutosPalet}</TableCell>
        <TableCell align="center">{row.porteProduto}</TableCell>
        <TableCell align="center">{row.fornecedor}</TableCell>
        <TableCell align="center">{row.operadores}</TableCell>
        <TableCell align="center">{row.produtoMetaHora}</TableCell>
        <TableCell align="center">{row.taxaAproveitamento}</TableCell>
        <TableCell>{row.observacoes}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {loading ? (
                <p>Carregando dados...</p>
              ) : (
                <TableContainerMui component={Paper}>
                  <Table size="medium" aria-label="subrow">
                    <TableHeadMui
                      sx={{
                        bgcolor: '#f3f3f3',
                        color: '#333333',
                      }}
                    >
                      <TableRow>
                        {row.listaInsumosUtilizados?.map((item, index) => (
                          <TableCell
                            sx={{ fontWeight: 'bold', fontSize: '11px' }}
                            key={index}
                          >
                            {item.insumo.nome}
                          </TableCell>
                        ))}
                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '11px' }}
                        >
                          PESO BRUTO(KG)
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '11px' }}
                        >
                          PESO PALETE (KG)
                        </TableCell>
                      </TableRow>
                    </TableHeadMui>
                    <TableBody>
                      <TableRow>
                        {row.listaInsumosUtilizados?.map((item, index) => (
                          <TableCell
                            key={index}
                            sx={{ fontWeight: 'bold', fontSize: '11px' }}
                          >
                            {item.qtdInsumosUsados || '-'}
                          </TableCell>
                        ))}

                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '11px' }}
                        >
                          {row.pesoBruto || '-'}
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '11px' }}
                        >
                          {row.pesoPalet || '-'}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainerMui>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default function NewTable({ data }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  useState(() => {
    if (page > Math.ceil(data.length / rowsPerPage)) {
      setPage(0);
    }
  }, [rowsPerPage, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer
      component={Paper}
      style={{
        maxHeight: 696,
        width: '95%',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
      }}
      sx={{
        '&::-webkit-scrollbar': { display: 'none' },
      }}
    >
      <Table stickyHeader aria-label="collapsible table">
        <TableHead>
          <TableRow sx={{ bgcolor: '#F3F3F3' }}>
            <TableCell sx={{ fontWeight: 'bold' }}>Insumos</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Código</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Descrição</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Qtd no Palete</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Porte Produto</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Fornecedor</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Operadores</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Meta Hora</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>
              Tx. Aproveitamento
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Observações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item) => {
              return <Row key={item.id} row={item} />;
            })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              count={data.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Linhas por página"
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
