import { useState, useEffect, useMemo, useCallback } from 'react';
import LayoutNovo from '../../components/LayoutNovo';
import {
  Grid,
  Box,
  Typography,
  TextField,
  InputAdornment,
  Snackbar,
  Alert,
} from '@mui/material';

import { useNavigate } from 'react-router';
import Amvoxlogopng from '../../assets/Amvoxlogopng.png';
import SearchIcon from '@mui/icons-material/Search';
import { useToast } from '../../hooks/components/toast.hook';
import NewTable from './components/NewTable';
import ModalRegistro from './components/Modal';
import {
  BuscarProdutoMetahoraGet,
  ProdutoMetahoraPost,
} from '../../services/tabelaMestra.service';
import { set } from 'date-fns';
import { da } from 'date-fns/locale';
import { ModalCadastro } from './components/ModalCadastro';

const interfaceFiltro = {
  Codigo: '',
  Pagina: 1,
  QuantidadeItensPagina: 200,
};

const dadosObj = {
  dados: [],
  totalLinhas: 0,
};

export default function TabelaMestra() {
  const navigate = useNavigate();
  const [data, setData] = useState(dadosObj);
  const [filtro, setFiltro] = useState(interfaceFiltro);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const { addToast } = useToast();
  const handleLogoClick = () => navigate('/principal');

  const SubmitMetaHora = async (body) => {
    ProdutoMetahoraPost(body)
      .then(() => {
        addToast({
          type: 'success',
          description: 'Cadastrado com sucesso',
        });
        setShowSuccessMessage(true);
        GetDados();
      })
      .catch(() => {
        addToast({
          type: 'danger',
          description: 'Error ao cadastrar',
        });
      });
  };

  const GetDados = useCallback(() => {
    BuscarProdutoMetahoraGet(filtro)
      .then((res) =>
        setData({
          dados: res.listaTabelaMestra,
          totalLinhas: res.totalLinhas,
        })
      )
      .catch(() =>
        addToast({
          type: 'danger',
          description: 'Erro ao buscar tabela',
        })
      );
  }, [filtro]);

  useEffect(() => {
    GetDados();
  }, [filtro]);

  // Mensagem de sucesso
  useEffect(() => {
    if (showSuccessMessage) {
      const timer = setTimeout(() => setShowSuccessMessage(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [showSuccessMessage]);

  return (
    <LayoutNovo>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '95%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Box onClick={handleLogoClick} sx={{ cursor: 'pointer' }}>
              <img
                src={Amvoxlogopng}
                style={{ width: '150px', height: '100px', cursor: 'pointer' }}
              />
            </Box>
            <Typography
              sx={{ fontWeight: '500', color: '#000', fontSize: '30px' }}
            >
              Tabela Mestra
            </Typography>
          </Box>

          {showSuccessMessage && (
            <Snackbar
              open={showSuccessMessage}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              onClose={() => setShowSuccessMessage(false)}
            >
              <Alert
                onClose={() => setShowSuccessMessage(false)}
                variant="filled"
                severity="success"
                sx={{
                  borderRadius: '25px',
                  fontWeight: 'bold',
                  fontSize: '14px',
                  fontFamily: 'Poppins',
                }}
              >
                Registro realizado com sucesso!
              </Alert>
            </Snackbar>
          )}
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Grid item xs={6} sm={3} md={2} xl={1.5}>
              <TextField
                size="small"
                variant="outlined"
                placeholder="Código do produto"
                value={filtro.Codigo}
                onChange={(e) =>
                  setFiltro({ ...filtro, Codigo: e.target.value })
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{ bgcolor: '#fff', borderRadius: 2, width: '280px' }}
              />
            </Grid>
            <ModalCadastro />
            <ModalRegistro handleSubmit={SubmitMetaHora} GetDados={GetDados} />
          </Box>
        </Box>
        <NewTable
          data={data.dados}
          totalLinhas={data.totalLinhas}
          GetDados={GetDados}
        />
      </Box>
    </LayoutNovo>
  );
}
