import React, { memo, useEffect, useState, useCallback } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { useMemo } from 'react';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { IconButton, TextField, Input, MenuItem, Select } from '@mui/material';
import { Modal, Button, FormLabel } from 'react-bootstrap';
import { apiFabrica } from '../../../services/apis';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import EditIcon from '@mui/icons-material/Edit';
import { useToast } from '../../../hooks/components/toast.hook';
import moment from 'moment';
import '../styles.css';
import { formatDateVisual } from '../../../utils/formatDateInput';

const interfacePutExpedicao = {
  id: 0,
  doca: '',
  status: '',
  prioridade: '',
};

const interfaceStatusCadastro = [
  {
    id: 1,
    status: 'EM DOCA',
  },
  {
    id: 2,
    status: 'EM CARREGAMENTO',
  },
  {
    id: 3,
    status: 'EM DESCARGA',
  },
  {
    id: 4,
    status: 'ACESSANDO O PATIO',
  },
  {
    id: 5,
    status: 'LIBERADO',
  },
  {
    id: 6,
    status: 'CANCELADO',
  },
  {
    id: 7,
    status: 'AGUARD. SEPARAÇÃO',
  },
];

const interfacePrioridade = [
  {
    id: 1,
    status: 'Alta',
  },
  {
    id: 2,
    status: 'Média',
  },
  {
    id: 3,
    status: 'Baixa',
  },
];

function TabelaExpedicaoProps({ listaExpedicao, handleFetch }) {
  const [showModal, setShowModal] = useState(false);
  const [atualizaExpedicao, setAtualizaExpedicao] = useState(
    interfacePutExpedicao
  );
  const [statusObj, setStatusObj] = useState(interfaceStatusCadastro);
  const [prioridadeObj, setPrioridadeObj] = useState(interfacePrioridade);
  const [isLoading, setIsLoading] = useState(false);

  function renderColorStatus(status) {
    switch (status) {
      case 'EM DOCA':
        return '#FFC470';
      case 'EM CARREGAMENTO':
        return '#FFC470';
      case 'EM DESCARGA':
        return '#FFC470';
      case 'ACESSANDO O PATIO':
        return '#DD5746';
      case 'LIBERADO':
        return '#799351';
      case 'CANCELADO':
        return '#DD5746';
        case 'AGUARD. SEPARAÇÃO':
        return '#f8a835';
      default:
        return undefined;
    }
  }

  const { addToast } = useToast();

  const AtualizarExpedicao = async (item) => {
    setIsLoading(true);
    try {
      await apiFabrica.put(
        `/ExpedicaoCarga?Id=${item.id}&Doca=${item.doca}&Status=${item.status}&Prioridade=${item.prioridade}`
      );
    } catch (err) {
      setIsLoading(false);
      addToast({
        type: 'warning',
        title: 'Erro ao atualizar expedição!',
      });
    } finally {
      setIsLoading(false);
      handleClose();
      handleFetch();
      addToast({
        type: 'success',
        title: 'Sucesso ao atualizar expedição!',
      });
    }
  };

  const inputTextHandler = (e) => {
    const { name, value } = e.target;

    setAtualizaExpedicao({ ...atualizaExpedicao, [name]: value });
  };

  const handleClose = () => {
    setShowModal(!showModal);
    setAtualizaExpedicao(interfacePutExpedicao);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'chegada',
        header: 'Hora De Chegada',
        size: 'small',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
        Cell: ({ cell }) => {
          return <p>{moment(cell.getValue()).format('DD/MM/yyyy HH:mm')}</p>;
        },
      },
      {
        accessorKey: 'prioridade',
        header: 'Prioridade',
        size: 'small',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'doca',
        header: 'Doca',
        size: 'small',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
        Cell: ({ cell }) => {
          return <b>{cell.getValue()}</b>;
        },
      },
      {
        accessorKey: 'destino',
        header: 'Destino/Cliente',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'uf',
        header: 'UF',
        size: 'small',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'motorista',
        header: 'Motorista',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'veiculo',
        header: 'Veiculo',
        size: 'small',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'cubagem',
        header: 'Cub',
        size: 'small',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'tipO_CARGA',
        header: 'Tipo Carga',
        size: 'small',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'transportadora',
        header: 'Tipo Transporte',
        size: 'small',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
        Cell: ({ cell }) => {
          return (
            <img
              width="150"
              height="30"
              src={`data:image/png;base64, ${cell.getValue()}`}
            />
          );
        },
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 'small',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
        Cell: ({ cell }) => {
          return (
            <TextField
              variant="outlined"
              margin="normal"
              size="small"
              inputProps={{
                style: {
                  fontSize: 12,
                  padding: 0,
                  textAlign: 'center',
                  color: 'white',
                },
              }}
              InputLabelProps={{ style: { fontSize: 12 } }}
              sx={{
                border: 'none',
                '& fieldset': { border: 'none' },
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: 'white',
                },
                backgroundColor: renderColorStatus(cell.getValue()),
                borderRadius: '10px',
                fontWeight: 'bold',
              }}
              disabled
              value={cell.getValue()}
            />
          );
        },
      },
      {
        header: 'Ações',
        Cell: ({ cell }) => {
          return (
            <IconButton
              aria-label="edit"
              onClick={(e) => {
                setAtualizaExpedicao({
                  id: cell.row.original.id,
                  doca: cell.row.original.doca,
                  status: cell.row.original.status,
                  FINALIZACAO: cell.row.original.finalizacao,
                });
                setShowModal(!showModal);
              }}
            >
              <EditIcon />
            </IconButton>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <Modal size="lg" show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Expedição</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: 'flex', justifyContent: 'space-around' }}>
        <div
            style={{ display: 'flex', flexDirection: 'column', width: '32%' }}
          >
            <FormLabel>Doca</FormLabel>
          <TextField
            type="text"
            id="doca"
            name="doca"
            value={atualizaExpedicao.doca}
            onChange={inputTextHandler}
          />
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '32%' }}
          >
          <FormLabel>Status</FormLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={atualizaExpedicao.status}
            label="Status"
            onChange={(e) =>
              setAtualizaExpedicao({
                ...atualizaExpedicao,
                status: e.target.value,
              })
            }
          >
            {statusObj.map((item) => (
              <MenuItem key={item.id} value={item.status}>
                {item.status}
              </MenuItem>
            ))}
          </Select>
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '32%' }}
          >
            <FormLabel>Prioridade</FormLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={atualizaExpedicao.prioridade}
              label="Prioridade"
              onChange={(e) =>
                setAtualizaExpedicao({
                  ...atualizaExpedicao,
                  prioridade: e.target.value,
                })
              }
            >
              {prioridadeObj.map((item) => (
                <MenuItem key={item.id} value={item.status}>
                  {item.status}
                </MenuItem>
              ))}
            </Select>
          </div>
        </Modal.Body>
        <div
          style={{
            margin: 'auto',
          }}
        >
          <LoadingButton
            loading={isLoading}
            style={{
              backgroundColor: 'green',
              color: 'white',
              width: '200px',
              marginTop: '20px',
              marginBottom: '20px',
            }}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            onClick={() => {
              AtualizarExpedicao(atualizaExpedicao);
            }}
          >
            <span>Salvar</span>
          </LoadingButton>
        </div>
      </Modal>
      <MaterialReactTable
        data={listaExpedicao}
        columns={columns}
        enableColumnFilters={false}
        enableStickyHeader
        sx={{
          'tbody tr:hover': {
            backgroundColor: 'aliceblue',
        }
        }}
        initialState={{ density: 'compact', pagination: { pageSize: 10 } }}
        muiTableContainerProps={{ sx: { maxHeight: 600 } }}
        muiTopToolbarProps={{ sx: { bgcolor: '#56A0F5', color: 'white' } }}
        muiTableHeadCellProps={{
          sx: {
            backgroundColor: 'white',
            color: 'black',
          },
        }}
        muiTableBodyProps={{
          sx: {
            '& tr:nth-of-type(odd)': {
              backgroundColor: 'white',
            },
            '& tr:nth-of-type(even)': {
              backgroundColor: '#ECECEC',
            },
          },
        }}
        localization={MRT_Localization_PT_BR}
      />
    </>
  );
}

export const TabelaExpedicao = memo(
  TabelaExpedicaoProps,
  (prevProps, nextProps) => {
    Object.is(prevProps, nextProps);
  }
);
