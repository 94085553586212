import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import './../styles.css';
import { Typography } from '@mui/material';

const useStyles = makeStyles({
  table: {
    fontFamily: 'Poppins',
    fontSize: 12,
    fontStyle: 'normalize',
    lineHeight: 'normal',
  },
});

function createData(name, calories) {
  return { name, calories };
}

const rows = [
  createData('dashboard', '0'),
  createData('dashComercial', '0'),
  createData('entradaprotocolonotas', '0'),
  createData('coletaeentrega', '0'),
];

export default function PagesTable() {
  const classes = useStyles();

  return (
    <TableContainer>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography className="fontCardTitle">Página</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography className="fontCardTitle">Carregamento</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.calories}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
