import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { Box, Button, Tooltip } from '@mui/material';
import { formatDateTime } from '../../../utils/formatDateInput';
import { Link } from 'react-router-dom';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import formatDateTotvs from '../../../utils/formatDataTotvs';


const TabelaDeEntregas = ({ data, rowCount, loading, handleChangeExpedicao, handleNewRender, restartRenderizacao }) => {

  const [infoData, setInfoData] = useState({
    userLog: null,
    notaFiscal: null,
    dataProducaoNewRender: null,
    dataCarregamentoNewRender: null,
    dataTransporteNewRender: null,
    dataEntregueNewRender: null,
    dataUserLogNewRender: null,
  });

  useEffect(() => {
    handleNewRender(infoData);
  }, [infoData]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'documento',
        header: <p>Nota<br /> Fiscal</p>,
        size: 10,
        Cell: ({ cell }) => {
          return (
            <Link
              to={`/notafiscal/${cell.getValue()}`}
              title="Visualizar Espelho da Nota Fiscal"
            >
              {cell.getValue()}
            </Link>
          )
        },
      },
      {
        accessorKey: 'nome',
        header: 'Nome',
        size: 100,
      },
      {
        accessorKey: 'destino',
        header: 'Dest.',
        size: 20,
      },
      {
        accessorKey: 'dias',
        header: 'Dias',
        Cell: ({ cell }) => {
          if (cell.getValue() < 10) {
            return <div><SentimentVerySatisfiedIcon color="success" /> {cell.getValue()} </div>;
          } else {
            return <div><MoodBadIcon sx={{ color: '#ff0000' }} /> {cell.getValue()}</div>;
          }
        },
        size: 20,
      },
      {
        accessorKey: 'previsao',
        header: 'Previsão',
        Cell: ({ cell }) => {
          return (
            <Box component={'span'}>
              {
                cell.getValue() === "" ? '--/--/----' : formatDateTotvs(cell.getValue())
              }
            </Box>
          )
        },
        size: 50,
      },
      {
        accessorKey: 'entregue',
        header: 'Entregue',
        Cell: ({ cell }) => {
          return (
            <Box component={'span'}>
              {
                cell.getValue() === "" ? '--/--/----' : formatDateTotvs(cell.getValue())
              }
            </Box>
          )
        },
        size: 50,
      },
      {
        accessorKey: 'expedido',
        header: 'Exp.',
        Cell: ({ cell, row }) => {
          if (cell.getValue() === 1) {
            return <div> <Button disabled><ThumbUpIcon color="success" /> </Button></div>;
          } else {
            return <Button onClick={() => handleChangeExpedicao(row.original.id)}> <ThumbDownIcon sx={{ color: '#ff0000' }} /></Button>;
          }
        },
        size: 20,
      },
      {
        accessorKey: 'classificFrete',
        header: 'Frete',
        Cell: ({ cell }) => {
          return (
            cell.getValue() === '0001' ? '1 - VENDA' :
              cell.getValue() === '0002' ? '2 - POS VENDA' :
                cell.getValue() === '0003' ? '3 - VENDA FUNCIONÁRIO' :
                  cell.getValue() === '0004' ? '4 - RETIRADA FOB/FRATOS' :
                    cell.getValue() === '0005' ? '5 - BONIFICAÇÃO MKT' : ''
          )
        },
        size: 100,
      },
      {
        accessorKey: 'romaneio',
        header: 'Romaneio',
        size: 150,
      },
      {
        accessorKey: 'motoristaId',
        header: <p>Id<br /> Motorista</p>,
        size: 150,
      },
      {
        accessorKey: 'motoristaNome',
        header: <p>Nome<br /> Motorista</p>,
        size: 150,
      },
      {
        accessorKey: 'transportadoraCod',
        header: <p>Cód.<br />Transportadora</p>,
        size: 150,
      },
      {
        accessorKey: 'transportadoraNome',
        header: <p>Nome<br />Transportadora</p>,
        size: 150,
      },
    ],
    [],
  );
  return <MaterialReactTable columns={columns} data={data} //enable some features
    muiTopToolbarProps={{ sx: { bgcolor: '#56a0f5', color: '#fff' } }}
    muiTableHeadCellProps={{ sx: { bgcolor: '#56a0f5', color: '#fff' } }}
    enableColumnFilters={false}
    initialState={{ density: 'compact', pagination: { pageSize: 50 },  }}
    state={{ isLoading: loading }}
    muiTableContainerProps={{ sx: { maxHeight: 600 } }}
    enableStickyHeader
    enableGrouping
    
    muiTableBodyRowProps={({
      row
    }) => ({
      onMouseEnter: () => 
        setInfoData({
            userLog: row.original.userLog,
            notaFiscal: row.original.documento,
            dataProducaoNewRender: row.original.emissao,
            dataCarregamentoNewRender: row.original.saida,
            dataTransporteNewRender: row.original.previsao,
            dataEntregueNewRender: row.original.entregue,
            dataUserLogNewRender: row.original.userLog,
          }),
      onMouseLeave: ()   => restartRenderizacao(),
      sx: {
        backgroundColor: row.getValue('classificFrete') !== '0001' ? 'rgba(255, 54, 33, 0.18)' : '',
      },
    })}
  //   enableRowActions
  //   renderRowActions={({ row }) => (
  //     <Box>
  //         <Tooltip arrow placement="left" title="Expedido?">
  //           {
  //             row.original.expedido === 0 ? (
  //               <Button onClick={() => handleChangeExpedicao(row.original.id)}> <ThumbDownIcon sx={{ color: '#ff0000' }} /></Button>
  //             ) : (
  //               <Button disabled><ThumbUpIcon color="success" /> </Button>
  //             )
  //           }
  
  //         </Tooltip>
  //     </Box>
  // )}
  />;
};

export default TabelaDeEntregas;
