import React from 'react';

import error from '../assets/error1.jpg';

const UsuarioBloqueado = () => {
  return (
    <div className="container">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <img src={error} height="200" />
        <h1>Usuário Bloqueado</h1>
        <a href="/">ir para login.</a>
      </div>
    </div>
  );
};

export default UsuarioBloqueado;
