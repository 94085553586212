import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { Form, Modal, InputGroup, Button } from 'react-bootstrap';
import { BiSearch } from 'react-icons/bi';

import { useToast } from '../../hooks/components/toast.hook';

import LoaderBasico from '../LoaderBasico';
import debounce from '../../utils/debounce';

const BarcodeNotaFiscalConfirmacao = ({ isShow, handleClose, fn, isLoading, children }) => {
  const [entrega, setEntrega] = useState({ chaveNf: '' });

  const { addToast } = useToast();

  const buscarEntregaHandle = useCallback((chaveNf) => {
    fn(chaveNf)
      .then(() => {
        addToast({
          type: 'success',
          title: 'Nota Fiscal Confirmada',
          description: 'Nota alterada para Expedida.',
        });
      })
      .catch(err => {
        const error = err.response.data;
        addToast({
          type: 'danger',
          title: 'Erro na requisição',
          description: error,
        });
      })
      .finally(() => setEntrega({ chaveNf: '' }));
  }, [entrega, fn, addToast]);

  return (
    <Modal size="lg" show={isShow} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Verificação de Nota Fiscal</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p style={{ color: 'black' }}>
          Faça a leitura da nota com o leitor de código de barras, e aguarde a
          confirmação.
        </p>
        <div className="mt-3">
          <Form.Group>
            <Form.Label>Código de Barras</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                value={entrega.chaveNf}
                onChange={(e) => {
                  debounce(() => buscarEntregaHandle(e.target.value), 1000);
                  setEntrega({ chaveNf: e.target.value });
                }}
                readOnly={isLoading}
              />
              <Button variant="outline-secondary" onClick={buscarEntregaHandle}>
                {isLoading ? <LoaderBasico /> : <BiSearch size={20} />}
              </Button>
            </InputGroup>
          </Form.Group>
          {children}
        </div>
      </Modal.Body>
    </Modal>
  );
};

BarcodeNotaFiscalConfirmacao.propTypes = {
  isShow: PropTypes.bool,
  handleClose: PropTypes.func,
  fn: PropTypes.func,
  isLoading: PropTypes.bool,
  children: PropTypes.element
};

export default BarcodeNotaFiscalConfirmacao;
