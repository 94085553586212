import { apiFabricaAPOIO } from './apis';

export const buscarNotasExpedicao = async (filtro) => {
  try {
    const result = await apiFabricaAPOIO.get(`Dashboard/NotasExpedicao`, {
      params: filtro,
    });

    return Promise.resolve(result.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const graficoNotasExpedicao = async (params) => {
  try {
    const result = await apiFabricaAPOIO.get(`/Dashboard/NotasExpedicaoGrafico`, { params });
    return Promise.resolve(result.data);
  } catch (error) {
    return Promise.reject(error);
  }
};
