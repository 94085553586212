import React, { useEffect, useState } from 'react';
import './styles.css';
import Box from '@mui/material/Box';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Button, Typography, Alert } from '@mui/material';
import { useNavigate } from 'react-router';
import { IconButton } from '@mui/material';
import Amvoxlogopng from '../../../assets/Amvoxlogopng.png';
import ModalRegistro from './ModalRegistro';
import { ModalRegistroProdutos } from './ModalRegistroProdutos';
import { useToast } from '../../../hooks/components/toast.hook';
import TabelaPlanejamento from './tabela';
import Loader from '../../../components/Loader';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import SidebarNovo from '../../../components/LayoutNovo/SidebarNovo';
import xVermelho from '../../../assets/xVermelho.svg';
import TitlePageV3 from '../../../components/TitlePageV3';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import {
  RegistrarApontamentoFinal,
  ConsultarPlanejamento,
  ConsultaProdutos,
} from '../../../services/planejamentoSemanalProducao/planejamentoProducao.service';

export function PlanejamentoProducao() {
  const [produtosLista, setProdutosLista] = useState([]);
  const [planejamentoProducaoLista, setPlanejamentoProducaoLista] = useState({
    produtos: [],
    executado: 0,
    planejado: 0,
  });
  const [dadosPlanejamento, setDadosPlanejamento] = useState({});
  const [modal1Aberto, setModal1Aberto] = useState(false);
  const [modal2Aberto, setModal2Aberto] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isComfirmed, setIsComfirmed] = useState(false);

  const navigate = useNavigate();
  const { addToast } = useToast();

  const handleAbrirModal1 = () => {
    setModal1Aberto(true);
  };

  const handleContinuar = (dados) => {
    setDadosPlanejamento(dados);
    setModal1Aberto(false);
    setModal2Aberto(true);
  };

  const handleCadastrarProduto = async (formData) => {
    setIsLoading(true);
    try {
      await RegistrarApontamentoFinal(formData);
      addToast({
        type: 'success',
        title: 'Registro efetuado.',
        description: 'Quantidade produzida foi registrada com sucesso.',
      });
      setIsLoading(false);
      setIsComfirmed(true);
      setTimeout(() => setIsComfirmed(false), 3000);
      setModal2Aberto(false);
      handleFetchAtt();
    } catch (error) {
      addToast({
        type: 'danger',
        title: 'Erro ao registrar planejamento',
        description: '',
      });
      console.log(error.response);

      setIsLoading(false);
    }
  };

  const handleFetchAtt = () => {
    ConsultaProdutos()
      .then((res) => {
        setProdutosLista(res);
      })
      .catch((_err) => {
        addToast({
          type: 'danger',
          title: 'Erro ao consultar produtos',
          description: '',
        });
      });
    ConsultarPlanejamento()
      .then((res) => {
        setPlanejamentoProducaoLista({
          produtos: res.data.listaPlanejamento,
          executado: res.data.executado,
          planejado: res.data.planejado,
        });
        setLoading(false);
      })
      .catch((_err) => {
        addToast({
          type: 'danger',
          title: 'Erro ao consultar planejamento',
          description: '',
        });
        console.log(_err.response);
      });
  };

  useEffect(() => {
    handleFetchAtt();
  }, []);

  return (
    <>
      <Box className="Principal">
        <Box className="sidebar">
          <SidebarNovo />
        </Box>
        <Box
          position={'relative'}
          sx={{ backgroundColor: '#FAFAFA' }}
          gap={2}
          padding={'32px'}
        >
          <Box position={'relative'}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                pr: 2,
              }}
            >
              <Box
                sx={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  display: 'flex',
                  width: '100%',
                }}
              >
                <IconButton onClick={() => navigate(-1)}>
                  <ChevronLeftIcon />
                </IconButton>
                <img src={Amvoxlogopng} alt="Amvox" className="imgAmvox"></img>
                <Box
                  sx={{
                    display: 'inline-table',
                    alignItems: 'center',
                    display: 'inline-flex',
                    width: '100%',
                  }}
                >
                  <TitlePageV3 title={'Planejamento Produção'} />
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                    gap: '8px',
                  }}
                >
                  {isLoading && (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: '1px solid #AA0000',
                        color: '#AA0000',
                        padding: ' 8px',
                        borderRadius: '4px',
                        gap: '8px',
                        width: '200px',
                      }}
                    >
                      <img src={xVermelho} alt="Amvox"></img>
                      <Typography sx={{ fontWeight: 'bold' }}>
                        Carregando...
                      </Typography>
                    </Box>
                  )}
                  {isComfirmed && (
                    <Alert variant="filled" severity="success">
                      Planejamento confirmado!
                    </Alert>
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'flex-end',
                    gap: '8px',
                  }}
                >
                  <Button
                    sx={{
                      padding: '4px 16px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontFamily: 'Poppins, sans-serif',
                      borderRadius: '4px',
                      border: '1px solid rgba(0, 0, 0, 0.10)',
                      boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.25)',
                      color: '#333',

                      transition:
                        'background-color 0.5s ease, transform 0.3s ease-in-out',
                      '&:hover': {
                        border: '1px solid rgba(0, 0, 0, 0.10)',
                        transform: 'scale(1.1)',
                        fontFamily: 'Poppins, sans-serif',
                        transition:
                          'background-color 0.5s ease, transform 0.3s ease-in-out',
                      },
                    }}
                    variant="outlined"
                    onClick={handleFetchAtt}
                    startIcon={
                      <AutorenewIcon sx={{ width: '24px', height: '24px' }} />
                    }
                  >
                    Atualizar
                  </Button>
                  <Button
                    onClick={handleAbrirModal1}
                    variant="contained"
                    color="success"
                    size="large"
                    startIcon={<PlaylistAddIcon />}
                    type="reset"
                  >
                    Registrar
                  </Button>
                  <ModalRegistro
                    open={modal1Aberto}
                    onContinuar={handleContinuar}
                    onClose={() => setModal1Aberto(false)}
                  />
                  <ModalRegistroProdutos
                    produtosLista={produtosLista}
                    handleAtt={handleFetchAtt}
                    open={modal2Aberto}
                    data={dadosPlanejamento}
                    onClose={() => setModal2Aberto(false)}
                    handleSubmit={handleCadastrarProduto}
                  />
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', gap: '16px' }}>
              <Box className="boxCard">
                <PrecisionManufacturingIcon
                  sx={{
                    bgcolor: 'green',
                    color: '#fff',
                    borderRadius: '10px',
                    width: '50px',
                    height: '50px',
                    padding: '10px',
                  }}
                />
                <Box sx={{ flexDirection: 'column' }}>
                  <Typography
                    sx={{
                      display: 'flex',
                      fontFamily: 'Poppins, sans-serif',
                      color: '#666',
                      fontStyle: 'normal',
                      fontWeight: '400',
                    }}
                  >
                    Quantidade Planejada
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      display: 'flex',
                      fontFamily: 'Poppins, sans-serif',
                      color: '#333',
                      fontStyle: 'normal',
                      fontWeight: '700',
                    }}
                  >
                    {planejamentoProducaoLista.planejado}
                  </Typography>
                </Box>
              </Box>
              <Box className="boxCard">
                <DoneOutlineIcon
                  sx={{
                    bgcolor: 'green',
                    color: '#fff',
                    borderRadius: '10px',
                    width: '50px',
                    height: '50px',
                    padding: '10px',
                  }}
                />
                <Box sx={{ flexDirection: 'column' }}>
                  <Typography
                    sx={{
                      display: 'flex',
                      fontFamily: 'Poppins, sans-serif',
                      color: '#666',
                      fontStyle: 'normal',
                      fontWeight: '400',
                    }}
                  >
                    Quantidade Executada{' '}
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      display: 'flex',
                      fontFamily: 'Poppins, sans-serif',
                      color: '#333',
                      fontStyle: 'normal',
                      fontWeight: '700',
                    }}
                  >
                    {planejamentoProducaoLista.executado}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            margin={1}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            gap={2}
          >
            {loading ? (
              <Loader />
            ) : (
              <Box
                sx={{
                  borderRadius: '16px',
                  width: '100%',
                  mt: '16px',
                }}
              >
                <TabelaPlanejamento
                  listaPlanejamento={planejamentoProducaoLista.produtos}
                  handleAtt={handleFetchAtt}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',

          opacity: '85%',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#000000',
        }}
      >
        {' '}
        <footer className="footerPagePrincipal">
          <img src={xVermelho} alt="Amvox"></img>
          Copyright &copy; {new Date().getFullYear()} Amvox. Todos os direitos
          reservados.
          <br />
        </footer>
      </Box>
    </>
  );
}
