import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles({
  table: {
    minWidth: 660,
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 'normal',
  },
});

export default function UserTableLog({ data }) {
  const classes = useStyles();

  return (
    <TableContainer>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Usuário</TableCell>
            <TableCell align="left">Email</TableCell>
            <TableCell align="center">Última Requisição</TableCell>
            <TableCell align="center">Load Time</TableCell>
            <TableCell align="center">Data</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length > 0 ? (
            data.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.userName}
                </TableCell>
                <TableCell align="left">{row.userEmail}</TableCell>
                <TableCell align="center">{row.endpointRequest}</TableCell>
                <TableCell align="center">{row.loadTime}</TableCell>
                <TableCell align="center">{row.requestDate}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5} align="center">
                Nenhum dado disponível
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
