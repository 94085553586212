import React from 'react';
import { useState } from 'react';
import {
  Box,
  FormControl,
  Button,
  Modal,
  TextField,
  Typography,
  IconButton,
  MenuItem,
  Select,
  Autocomplete,
  InputLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { set } from 'date-fns';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  maxWidth: '100%',
  maxHeight: '90%',
  boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.25)',
  overflowY: 'auto',
  borderRadius: '8px',
  flexDirection: 'row',
  padding: '24px',
};

const inputStyles = {
  width: '100%',
  borderRadius: '4px',
  marginBottom: '14px',
  border: '1px solid #CCC',
  background: '#FFF',
  '& .MuiInputBase-root': {
    height: '32px',
    borderRadius: '4px',
  },
};

export const ModalRegistroProdutos = ({
  produtosLista,
  data,
  open,
  onClose,
  handleSubmit,
}) => {
  const [selectValue, setSelectValue] = useState(null);
  const [produtos, setProdutos] = useState([]);
  const [produtoAtual, setProdutoAtual] = useState({
    codigo: '',
    quantidade: '',
    op: '',
    observacao: '',
    pendencia: '',
    prioridade: '',
    qtdColaboradores: 0,
  });
  const [errors, setErrors] = useState({});

  const prioridadeSelect = [
    { value: '1', label: 'Alta' },
    { value: '2', label: 'Média' },
    { value: '3', label: 'Baixa' },
  ];

  const selectProdutosLista = produtosLista.map((item) => ({
    value: item.codigo,
    label: item.apelido,
  }));

  const handleInputChange = (e, fieldName) => {
    setProdutoAtual({ ...produtoAtual, [fieldName]: e.target.value });
    if (e.target.value) {
      setErrors((prevErrors) => {
        const { [fieldName]: _, ...restErrors } = prevErrors;
        return restErrors;
      });
    }
  };

  const handleAddProduto = () => {
    const validationErrors = {};

    if (!selectValue) {
      validationErrors.produto = 'Campo obrigatório.*';
    }
    if (!produtoAtual.quantidade) {
      validationErrors.quantidade = 'Campo obrigatório.*';
    }
    if (!produtoAtual.op) {
      validationErrors.op = 'Campo obrigatório.*';
    }
    if (!produtoAtual.prioridade) {
      validationErrors.prioridade = 'Campo obrigatório.*';
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const newProduto = {
      ...produtoAtual,
      produto: selectValue ? selectValue.label : 'Não informado',
    };

    setProdutos((prevProdutos) => {
      const updatedProdutos = [...prevProdutos, newProduto];

      updatedProdutos.sort((a, b) => a.prioridade - b.prioridade);
      return updatedProdutos;
    });

    setProdutoAtual({
      codigo: '',
      quantidade: '',
      op: '',
      observacao: '',
      pendencia: '',
      prioridade: '',
      qtdColaboradores: 0,
    });
    setSelectValue(null);
    setErrors({});
  };

  const handleSubmitFormData = () => {
    const semanaAnoNumerica = Number(data.semanaAno.split('W')[1]) || 0;

    const formData = {
      ...data,
      semanaAno: semanaAnoNumerica,
      horasDisponiveis: Number(data.horasDisponiveis) || 0,
      horasDisponiveisDia: Number(data.horasDisponiveisDia) || 0,
      linhasDisponiveis: Number(data.linhasDisponiveis) || 0,
      diasDisponiveis: Number(data.diasDisponiveis) || 0,
      produtos: produtos.map((produto) => ({
        ...produto,
        quantidade: Number(produto.quantidade) || 0,
        prioridade: Number(produto.prioridade) || 0,
        qtdColaboradores: Number(produto.qtdColaboradores) || 0,
      })),
    };

    handleSubmit({
      ...formData,
    });

    onClose();
    handleClear();
    handleFechar();
  };

  const handleRemoveProduto = (index) => {
    setProdutos(produtos.filter((_, i) => i !== index));
  };

  const handleClear = () => {
    setProdutoAtual({
      codigo: '',
      quantidade: '',
      op: '',
      observacao: '',
      pendencia: '',
      prioridade: '',
      qtdColaboradores: 0,
    });
    setSelectValue(null);
    setErrors({});
  };

  const handleFechar = () => {
    setErrors({});
    setProdutos([]);
    setProdutoAtual({
      codigo: '',
      quantidade: '',
      op: '',
      observacao: '',
      pendencia: '',
      prioridade: '',
      qtdColaboradores: 0,
    });
    setSelectValue(null);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          onClose();
          handleClear();
        }}
      >
        <Box sx={style}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              maxwidth: '1216px',
            }}
          >
            <Typography
              id="modal-modal-title"
              sx={{ fontWeight: 'bold', fontSize: '16px' }}
            >
              Planejamento Semanal
            </Typography>
            <IconButton
              onClick={() => {
                onClose();
                handleClear();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                width: '500px',
              }}
            >
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                  mb: '24px',
                  mt: '24px',
                }}
              >
                Informe os produtos a serem produzidos na semana:{' '}
              </Typography>
              {/* Numero op / prioridade */}
              <Box
                sx={{
                  display: 'flex',
                  gap: '16px',
                  width: '100%',
                  flexWrap: 'wrap',
                }}
              >
                <Box sx={{ flex: '1 1 300px' }}>
                  <Typography variant="body2" sx={{ color: 'red' }}>
                    {errors.op}
                  </Typography>
                  <TextField
                    placeholder="Número OP"
                    sx={{ ...inputStyles }}
                    value={produtoAtual.op}
                    onChange={(e) => handleInputChange(e, 'op')}
                    required
                    error={Boolean(errors.op)}
                    autoComplete="off"
                  />
                </Box>

                <Box sx={{ flex: '1 1 180px' }}>
                  <Typography variant="body2" sx={{ color: 'red' }}>
                    {errors.prioridade}
                  </Typography>
                  <FormControl fullWidth sx={{ ...inputStyles }}>
                    <InputLabel id="prioridade-label" sx={{ mt: '-10px' }}>
                      Prioridade
                    </InputLabel>
                    <Select
                      labelId="prioridade-label"
                      value={produtoAtual.prioridade}
                      label="Prioridade"
                      onChange={(e) => handleInputChange(e, 'prioridade')}
                      required
                      error={Boolean(errors.prioridade)}
                    >
                      {prioridadeSelect.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              {/* produtos/quantidade */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '16px',
                  flexWrap: 'wrap',
                }}
              >
                <Box sx={{ flex: '1 1 300px' }}>
                  <Typography variant="body2" sx={{ color: 'red' }}>
                    {errors.produto}
                  </Typography>
                  <FormControl fullWidth sx={{ ...inputStyles }}>
                    <Autocomplete
                      id="autocomplete-produto"
                      options={selectProdutosLista}
                      getOptionLabel={(option) => option.label}
                      value={selectValue}
                      onChange={(event, newValue) => {
                        setSelectValue(newValue);
                        handleInputChange(
                          { target: { value: newValue ? newValue.value : '' } },
                          'codigo'
                        );

                        if (newValue) {
                          setErrors((prevErrors) => {
                            const { produto, ...restErrors } = prevErrors;
                            return restErrors;
                          });
                        }
                      }}
                      required
                      error={Boolean(errors.codigo)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Produto"
                          variant="outlined"
                          InputLabelProps={{
                            sx: {
                              transform: selectValue
                                ? 'translate(14px, -10px)'
                                : 'translate(14px, 4px)',
                              fontSize: '16px',
                              transition: 'transform 0.2s ease-in-out',
                            },
                            shrink: selectValue !== null,
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Box>
                <Box sx={{ flex: '1 1 180px' }}>
                  <Typography variant="body2" sx={{ color: 'red' }}>
                    {errors.quantidade}
                  </Typography>
                  <TextField
                    type="number"
                    placeholder="Quantidade"
                    sx={inputStyles}
                    value={produtoAtual.quantidade}
                    onChange={(e) => handleInputChange(e, 'quantidade')}
                    required
                    error={Boolean(errors.quantidade)}
                    autoComplete="off"
                  />
                </Box>
              </Box>
              <Box>
                <TextField
                  placeholder="Pendência"
                  sx={{ ...inputStyles, mb: '0' }}
                  value={produtoAtual.pendencia}
                  onChange={(e) => {
                    if (e.target.value.length <= 100)
                      handleInputChange(e, 'pendencia');
                  }}
                  autoComplete="off"
                />
                <Typography variant="body2" sx={{ mt: '0', mb: '14px' }}>
                  {`Quantidade de caracteres: ${produtoAtual.pendencia.length}/100`}
                </Typography>
              </Box>
              <Box>
                <TextField
                  placeholder="Observação do produto"
                  sx={{
                    width: '100%',
                    background: '#FFF',
                    borderRadius: '4px',
                    '& .MuiInputBase-root': {
                      height: '76px',
                      borderRadius: '4px',
                      display: 'flex',
                      alignItems: 'center',
                    },
                  }}
                  value={produtoAtual.observacao}
                  onChange={(e) => {
                    if (e.target.value.length <= 150)
                      handleInputChange(e, 'observacao');
                  }}
                  autoComplete="off"
                />
                <Typography variant="body2" sx={{ mb: '14px' }}>
                  {`Quantidade de caracteres: ${produtoAtual.observacao.length}/150`}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: '16px',
                }}
              >
                <Button
                  variant="outlined"
                  startIcon={<DeleteOutlineIcon />}
                  sx={{
                    color: '#999',
                    border: '1px solid #CCC',
                  }}
                  onClick={handleClear}
                >
                  Limpar
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    onClose();
                    handleFechar();
                  }}
                >
                  Fechar
                </Button>
                <Button variant="contained" onClick={handleAddProduto}>
                  Adicionar
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                padding: '16px',
                width: '652px',
                borderRadius: '16px',
                boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.25)',
              }}
            >
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}
              >
                <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                  Planejamento
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                    width: '100%',
                    height: 'auto',
                    padding: '16px',
                    borderRadius: '8px',
                    border: '1px solid #CCC',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                      padding: '10px',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        gap: '8px',
                      }}
                    >
                      <Typography variant="body2">
                        Semana do ano:{' '}
                        {data.semanaAno
                          ? data.semanaAno.split('-')[1].replace('W', '')
                          : 'Não disponível'}
                      </Typography>
                      <Typography variant="body2">
                        Dias disponíveis: {data.diasDisponiveis}
                      </Typography>
                      <Typography variant="body2">
                        Qtd Linhas Disponíveis: {data.linhasDisponiveis}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        gap: '8px',
                      }}
                    >
                      <Typography variant="body2">
                        Horas disponíveis: {data.horasDisponiveisDia}
                      </Typography>
                      <Typography variant="body2">
                        Horas disponíveis semana: {data.horasDisponiveis}
                      </Typography>
                      <Typography variant="body2">
                        Observação:{' '}
                        {data.observacao ? (
                          data.observacao
                        ) : (
                          <Typography
                            variant="body2"
                            sx={{ display: 'inline', color: '#999999' }}
                          >
                            Sem observaçôes.
                          </Typography>
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ maxHeight: '400px', overflowY: 'auto' }}>
                  {produtos.map((produto, index) => (
                    <Box key={index}>
                      <Box
                        sx={{
                          display: 'flex',
                          padding: '16px',
                          gap: '10px',
                          borderRadius: '8px',
                          border: '1px solid #CCC',
                          flexDirection: 'column',
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: '12px',
                          }}
                          variant="body2"
                        >
                          Número OP: {produto.op}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '12px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          variant="body2"
                        >
                          Prioridade:
                          <strong
                            style={{
                              color: '#FF5E5E',
                              marginLeft: '5px',
                              fontSize: '12px',
                            }}
                          >
                            {prioridadeSelect.find(
                              (p) => p.value === produto.prioridade
                            )?.label || 'Não definida'}
                          </strong>
                        </Typography>
                        <Typography sx={{ fontSize: '12px' }} variant="body2">
                          Produto: {produto.produto || 'Não informado'}
                        </Typography>
                        <Typography sx={{ fontSize: '12px' }} variant="body2">
                          Código: {produto.codigo}
                        </Typography>
                        <Typography sx={{ fontSize: '12px' }} variant="body2">
                          Quantidade: {produto.quantidade}
                        </Typography>
                        <Typography sx={{ fontSize: '12px' }} variant="body2">
                          Pendência:{' '}
                          {produto.pendencia || (
                            <Typography
                              variant="body2"
                              sx={{
                                display: 'inline',
                                color: '#999999',
                                fontSize: '12px',
                              }}
                            >
                              Sem pendências.
                            </Typography>
                          )}
                        </Typography>
                        <Typography sx={{ fontSize: '12px' }} variant="body2">
                          Observação:{' '}
                          {produto.observacao || (
                            <Typography
                              variant="body2"
                              sx={{
                                display: 'inline',
                                color: '#999999',
                                fontSize: '12px',
                              }}
                            >
                              Sem observaçôes.
                            </Typography>
                          )}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          mb: '10px',
                        }}
                      >
                        <Button
                          onClick={() => handleRemoveProduto(index)}
                          startIcon={
                            <DeleteOutlineIcon sx={{ color: '#999' }} />
                          }
                          sx={{
                            textTransform: 'capitalize',
                            color: '#999',
                          }}
                        >
                          Deletar planejamento
                        </Button>
                      </Box>
                    </Box>
                  ))}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    onClick={handleSubmitFormData}
                    sx={{
                      textTransform: 'capitalize',
                    }}
                    variant="contained"
                    color="success"
                    disabled={produtos.length === 0}
                  >
                    Confirmar planejamento
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
