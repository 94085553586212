import React, { useMemo, useState } from 'react';
import Chart from 'react-apexcharts';

export default function GraficoLinha(props) {
  const dataAtual = new Date();
  const passarParaDataOntem = new Date(
    dataAtual.setDate(dataAtual.getDate() - 1)
  );
  const dataOntem = passarParaDataOntem.toLocaleDateString('pt-BR');

//   const titulosSomados = props.apiTitulosSomados;

//   const totalDash = useMemo(() => {
//     let saldoVencido_ = 0;
//     let saldoAvencer_ = 0;
//     let saldoTotal_ = 0;

//     titulosSomados.forEach((item) => (saldoAvencer_ += item.saldo_Avencer));
//     titulosSomados.forEach((item) => (saldoVencido_ += item.saldo_Vencidos));
//     titulosSomados.forEach((item) => (saldoTotal_ += item.saldo_Total));

//     return {
//       saldoAvencer: saldoAvencer_,
//       saldoVencido: saldoVencido_,
//       saldoTotal: saldoTotal_,
//     };
//   }, [titulosSomados]);

  const [titulosSomadosGrafico, setTitulosSomadosGrafico] = useState({
    options: {
      chart: {
        type: 'area',
        width: 100,
        height: 35,
        sparkline: {
          enabled: true
        }
      },
      colors: ['#ff0'],
      title: {
        text: undefined,
      },
      dataLabels: {
        enabled: false,
      },
      formatter: function (value) {
        return `R$ ${value.toLocaleString('pt-BR')}`;
      },
      stroke: {
        curve: 'straight',
      },
      xaxis: {
        type: 'date',
        categories: ['01/01/2010', '31/12/2031'],
        // categories: ['2018-09-19T00:00:00.000Z', '2018-09-19T01:30:00.000Z'],
        labels: {
          style: {
            fontSize: '16px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 'bold',
          },
        },
      },
      tooltip: {
        style: {
          fontSize: '12px',
        },
        x: {
          format: 'dd/MM/yy HH:mm',
        },
      },
      legend: {
        fontSize: '16px',
        fontWeight: 'bold',
      },
      yaxis: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
        },
      },
    },
  });

  //   const seriegraf = [{
  //     name: 'series1',
  //     data: [31, 40, 28, 51, 42, 109, 100]
  //   }, {
  //     name: 'series2',
  //     data: [11, 32, 45, 32, 34, 52, 41]
  //   }]

  const seriegraf = [
    {
      name: 'Saldo Total',
      data: [0, 0, 0],
    },
  ];

  return (
    <Chart
      options={titulosSomadosGrafico.options}
      series={seriegraf}
      type="area"
      height={'30%'}
      width={'100%'}
    />
  );
}
