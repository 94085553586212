import { BsPersonWorkspace } from 'react-icons/bs';
import LayoutNovo from '../../components/LayoutNovo';
import NumberBox from '../../components/NumberBox';
import { useContext, useEffect, useState } from 'react';
import { useToast } from '../../hooks/components/toast.hook';
import { buscarLogUsuarios } from '../../services/gerenciamentoTi/logUsuario.service';
import UserAccessChart from './components/userAccessChart';
import ChartTop5 from './components/ChartTop5';
import { Box, Button, Typography } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import ModalAcessoRestrito from '../../components/ModalAcessoRestrito';
import { RestricaoContext } from '../../hooks/acesso-ti/acesso-restrito-ti.hook';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';


export default function GerenciamentoTi() {
  const { showModalAccess, LiberarAcesso, setRouteName, error } =
    useContext(RestricaoContext);
  const location = useLocation().pathname;
  const [usuarios, setUsuarios] = useState([]);
  const { addToast } = useToast();
  const [loader, setLoader] = useState(true);

  const navigate = useNavigate();

  function handleBack() {
    navigate('/inteligencia/');
  }

  useEffect(() => {
    setRouteName(location);
    buscarLogUsuarios()
      .then((res) => {
        setUsuarios(res.value);
      })
      .catch((_err) => {
        addToast({
          type: 'danger',
          title: 'Erro ao Log Usuarios',
          description: 'Erro ao Carregar  Log Usuarios !',
        });
      })
      .finally(() => setLoader(false));
  }, []);

  return (
    <LayoutNovo setorColor={'inteligencia'}>
      <Box sx={{ position: 'relative' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 2,
            '@media (max-width: 600px)': {
              flexDirection: 'column',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 2,
              textShadow: '-1px -1px 6px #000000',
              color: 'rgb(248, 249, 250)',
              p: [2],
            }}
          >
            <BsPersonWorkspace size={40} color={'#FFFFFF'} />
            <Typography variant="h4">Dashboard - TI</Typography>
          </Box>
          <Link to={'/gerenciamentoti/dashboard'}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                '@media (max-width: 600px)': {
                  width: '90%',
                },
              }}
              startIcon={<ShortcutIcon />}
            >
              Ir p/ Dashboard
            </Button>
          </Link>
        </Box>
        <Button
          variant="contained"
          color="success"
          onClick={handleBack}
          startIcon={<KeyboardBackspaceIcon />}
        >
          Voltar
        </Button>
        <NumberBox
          title="Usuários Logado - Dia"
          backcolor="#008000"
          amount={Number(usuarios.quantidadeDia)}
          footerlabel="considerando os armazens filtrados"
          icon="usuarios"
        />
        <ChartTop5 data={usuarios?.top5}></ChartTop5>
        <UserAccessChart
          data={usuarios.listaAcessoDiario ? usuarios.listaAcessoDiario : []}
        />
      </Box>
    </LayoutNovo>
  );
}
