import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button } from '@mui/material';
import Tour from 'reactour';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import './index.css'

  export default function GuiaUtilizacao(props) {
     const [isTourOpen, setIsTourOpen] = useState(false);
  
     
  const steps = useMemo(() => {
    return Object.values(props.guiaUtilConfig).map((config) => ({
      selector: config.selector,
      content: config.content,
    }));
  }, [props.guiaUtilConfig]);
 
    useEffect(() => {
      const blockMouse = (event) => {
        event.preventDefault();
      };
      const blockTecla = (event) => {
        event.preventDefault();
      };
      if (isTourOpen) {
        document.addEventListener('wheel', blockMouse, { passive: false });
        document.addEventListener('keydown', blockTecla, { passive: false });
      }
  
      return () => {
        document.removeEventListener('wheel', blockMouse);
        document.removeEventListener('keydown', blockTecla);
      };
    }, [isTourOpen]);
  
    const closeTour = () => {
      setIsTourOpen(false);
    };

  return (
    <div>                
  <Tour
    steps={steps}
    isOpen={isTourOpen}
    onRequestClose={closeTour}
    rounded={5}
    accentColor="#007aff"
  />
        <Box
      
            style={{
              position: 'absolute',
              left: '90%',
              bottom: '89%',
             
            }}
          >

            <Button
              className="bouncing-button"
              onClick={() => {setIsTourOpen(true)}}
              variant="contained"
              color="error"
            >
              <ContactSupportOutlinedIcon />
              Ajuda?
            </Button>
          </Box>
</div>
  );
};
 
